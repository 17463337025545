import React from 'react';
import { UserSettingsUIProvider } from './_context/UserSettingsUIContext';
import SettingsCard from './SettingsCard';



export default function Settings() {
  return (
    <UserSettingsUIProvider>
        <SettingsCard />
    </UserSettingsUIProvider>
  );
}