import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { ModuleRoutes, AdminRoutes } from 'constants/moduleRoutes';
import { checkIsActive } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';



export function AsideMenuAdmin({ disableScroll }) {

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => ({
    layoutConfig: uiService.config,
    asideMenuAttr: uiService.getAttributes('aside_menu'),
    ulClasses: uiService.getClasses('aside_menu_nav', true),
    asideClassesFromConfig: uiService.getClasses('aside_menu', true),
  }), [uiService]);

  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? `menu-item ${!hasSubmenu && 'menu-item-active'}`
      : 'menu-item';
  };

  const { unreadTickets } = useSelector(state => ({
    unreadTickets: state.supports.unreadTickets,
  }),
    shallowEqual
  );

  return (
    <div data-menu-vertical="1" className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto justify-content-between`} id="kt_aside_menu" {...layoutProps.asideMenuAttr}>


      {/* start::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>


        {/* ::begin:: Dashboard */}
        <li className={`${getMenuItemActive('/', true)}`}>
          <NavLink className="menu-link" to="/" id='menu_dashboard'>
            <span className="svg-icon menu-icon">
              <Icon.Admin />
            </span>
            <span className="menu-text">
              Admin <FormattedMessage id="MENU.ASIDE.DASHBOARD" />
            </span>
          </NavLink>
        </li>
        {/* ::end:: Dashboard */}


        {/* ::begin:: Admin-Controller */}
        <li className={`${getMenuItemActive(AdminRoutes.ADMIN_USERS)}`}>
          <NavLink className="menu-link" to={AdminRoutes.ADMIN_USERS} id='menu_users'>
            <span className="svg-icon menu-icon">
              <Icon.Customer />
            </span>
            <span className="menu-text">
              <FormattedMessage id="ADMIN.USERS.TITLE" />
            </span>
          </NavLink>
        </li>

        <li className={`${getMenuItemActive(AdminRoutes.ADMIN_TEMP_USERS)}`}>
          <NavLink className="menu-link" to={AdminRoutes.ADMIN_TEMP_USERS} id='menu_temp_users'>
            <span className="svg-icon menu-icon">
              <Icon.PersonExclamation />
            </span>
            <span className="menu-text">
              <FormattedMessage id="ADMIN.TEMP_USERS.TITLE" />
            </span>
          </NavLink>
        </li>

        <li className={`${getMenuItemActive(AdminRoutes.ADMIN_USERS_REPORTS)}`}>
          <NavLink className="menu-link" to={AdminRoutes.ADMIN_USERS_REPORTS} id='menu_reports_users'>
            <span className="svg-icon menu-icon">
              <Icon.Clipboard2Data />
            </span>
            <span className="menu-text">
              <FormattedMessage id="GENERAL.REPORT" />
            </span>
          </NavLink>
        </li>

        <li className={`${getMenuItemActive(AdminRoutes.ADMIN_USERS_CONTACT)}`}>
          <NavLink className="menu-link" to={AdminRoutes.ADMIN_USERS_CONTACT} id='menu_users_contact'>
            <span className="svg-icon menu-icon">
              <Icon.PersonLines />
            </span>
            <span className="menu-text">
              <FormattedMessage id="GENERAL.CONTACT" />
            </span>
          </NavLink>
        </li>
        {/* ::begin:: Orders */}
        <li className={`${getMenuItemActive(AdminRoutes.ADMIN_USER_ORDER)}`}>
          <NavLink className="menu-link" to={`${AdminRoutes.ADMIN_USER_ORDER}`} id='menu_order'>
            <span className="svg-icon menu-icon">
              <Icon.Order />
            </span>
            <span className="menu-text">
              <FormattedMessage id="DOCUMENT.ORDER_PLURAL" />
            </span>
          </NavLink>
        </li>
        {/* ::end:: Orders */}

        <li className={`${getMenuItemActive(AdminRoutes.ADMIN_USER_INVOICE)}`}>
          <NavLink className="menu-link" to={`${AdminRoutes.ADMIN_USER_INVOICE}`} id='menu_invoice'>
            <span className="svg-icon menu-icon">
              <Icon.Document />
            </span>
            <span className="menu-text">
              <FormattedMessage id="DOCUMENT.INVOICE_PLURAL" />
            </span>
          </NavLink>
        </li>
        {/* ::end:: Admin-Controller */}

      </ul>


      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        {/* ::begin:: Icon-Controller */}

        <li className={`${getMenuItemActive(ModuleRoutes.ICON)}`}>
          <NavLink className="menu-link" to={ModuleRoutes.ICON} id='menu_icons'>
            <span className="svg-icon menu-icon">
              <Icon.Salevali />
            </span>
            <span className="menu-text">
              Icons
            </span>
          </NavLink>
        </li>

        {/* ::end:: Icon-Controller */}


        {/* ::begin:: Level - Support */}
        <li className={`${getMenuItemActive(ModuleRoutes.SUPPORT)}`}>
          <NavLink className="menu-link" to={`${ModuleRoutes.SUPPORT_PAGE}?page=1&filter.stat.open=true`} id='menu_support'>
            <span className="svg-icon menu-icon">
              <Icon.Support />
            </span>
            <span className="menu-text">
              <FormattedMessage id="MENU.ASIDE.SUPPORT" />
              {unreadTickets > 0 &&
                <span className="label label-lg label-rounded label-light-primary ml-auto font-weight-bolder">
                  {unreadTickets > 99 ? "99+" : unreadTickets}
                </span>
              }
            </span>
          </NavLink>
        </li>
        {/* ::end:: Level - Support */}


        {/* ::begin:: Level - Settings */}
        <li className={`${getMenuItemActive(AdminRoutes.SETTINGS)} border-top border-secondary-50`}>
          <NavLink className="menu-link" to={AdminRoutes.SETTINGS} id='menu_settings'>
            <span className="svg-icon menu-icon">
              <Icon.Settings />
            </span>
            <span className="menu-text">
              <FormattedMessage id="SETTINGS.TITLE" />
            </span>
          </NavLink>
        </li>
        {/* ::end:: Level - Settings */}


      </ul>
      {/* end::Menu Nav */}

    </div>
  );
}
