import { call, put, takeEvery, select, fork } from 'redux-saga/effects';
import { adminMiddleware, documentMiddleware, tokenErrorHandler } from 'middlewares';
import { AuthActions } from 'app/pages/auth/_redux/actions';
import { AdminActions } from './actions';
import ActionTypes from './actionTypes';



// --------------------------- Users ---------------------------

// Get Users
function* getUsersSaga({ payload: { dateRange, queryParams } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const getUsers = yield call(adminMiddleware.getUsers, accessToken, dateRange, queryParams);
    const countUsers = yield call(adminMiddleware.getUsersCount, accessToken, '', queryParams);

    yield put(AdminActions.getUsersSuccess(getUsers.data, countUsers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getUsersFail(JSON.stringify(err.response)));
  }
}


// Get User
function* getUser({ payload: { id } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const getUser = yield call(adminMiddleware.getUser, accessToken, id);

    yield put(AdminActions.getUserSuccess(getUser.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getUserFail(JSON.stringify(err.response)));
  }
}


// Update User
function* updateUser({ payload: { id, user } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(adminMiddleware.patchUser, accessToken, id, user);
    yield put(AdminActions.updateUserSuccess(id, user));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.updateUserFail(err.response));
  }
}


// Delete User
function* deleteUser({ payload: { id, user, queryParams } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(adminMiddleware.deleteUser, accessToken, id, user);

    yield put(AdminActions.deleteUserSuccess(response.data));

    yield fork(getUsersSaga, { payload: { queryParams } });

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.deleteUserFail(err.response.data.error));
  }
}


// --------------------------- Temp Users ---------------------------

// Get Temp Users
function* getTempUsersSaga({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const countTempUsers = yield call(adminMiddleware.getTempUsersCount, accessToken, queryParams);
    const getTempUsers = yield call(adminMiddleware.getTempUsers, accessToken, queryParams);

    yield put(AdminActions.getTempUsersSuccess(getTempUsers.data, countTempUsers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getTempUsersFail(JSON.stringify(err.response)));
  }
}


// Update Temp user
function* updateTempUser({ payload: { tempUser } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(adminMiddleware.patchTempUser, accessToken, tempUser);
    yield put(AdminActions.updateTempUserSuccess());

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.updateTempUserFail(err.response));
  }
}


// Delete Temp User
function* deleteTempUser({ payload: { ids, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(adminMiddleware.deleteTempUser, accessToken, ids);
    yield put(AdminActions.deleteTempUserSuccess(ids));

    yield fork(getTempUsersSaga, { payload: { queryParams } });

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.deleteTempUserFail(err.response.data.error));
  }
}


// Get Users Reports
function* getReportsUsers({ payload: { dateRange, queryParams } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const getReports = yield call(adminMiddleware.getReportsUsers, accessToken, dateRange, queryParams);
    const countUsers = yield call(adminMiddleware.getUsersCount, accessToken, dateRange, queryParams);

    yield put(AdminActions.getReportsUsersSuccess(getReports.data, countUsers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getReportsUsersFail(JSON.stringify(err.response)));
  }
}


// Get User Details
function* getUserCompany({ payload: { id, filter } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const userDetail = yield call(adminMiddleware.getUserCompany, accessToken, id, filter);

    yield put(AdminActions.getUserCompanySuccess(userDetail.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getUserCompanyFail(err.response));
  }
}


function* getOrderReport({ payload: { id, filter } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const orderReport = yield call(adminMiddleware.getOrderReport, accessToken, id, filter);

    yield put(AdminActions.getOrderReportSuccess(orderReport.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getOrderReportFail(err.response));
  }
}


function* getUserLogEntries({ payload: { id, order, search, filter, page, pageSize } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const logEntries = yield call(adminMiddleware.getUserLogEntries, accessToken, id, order, search, filter, page, pageSize);

    yield put(AdminActions.getUserLogEntriesSuccess(logEntries.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getUserLogEntriesFail(err.response));
  }
}



// Get Users Contact
function* getUsersContact({ payload: { queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const getUsers = yield call(adminMiddleware.getUsersContact, accessToken, queryParams);
    const countUsers = yield call(adminMiddleware.getUsersCount, accessToken, '', queryParams);

    yield put(AdminActions.getUsersContactSuccess(getUsers.data, countUsers.data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getUsersContactFail(JSON.stringify(err.response)));
  }
}

// Get User Invoices
function* getUserInvoices({ payload: { queryParams, dateRange } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const userInvoices = yield call(adminMiddleware.getUserInvoices, accessToken, dateRange, queryParams);
    const countUsers = yield call(adminMiddleware.getUsersCount, accessToken, dateRange, queryParams);

    yield put(AdminActions.getUserInvoicesSuccess(userInvoices.data, countUsers.data));


  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getUserInvoicesFail());
  }
}
// Get User Invoice By Id
function* getUserInvoiceById({ payload: { id } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const userInvoice = yield call(adminMiddleware.getUserInvoice, accessToken, id);

    yield put(AdminActions.getUserInvoiceByIdSuccess(userInvoice.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getUserInvoiceByIdFail());
  }
}

// Get user invoice by ids
function* getUserInvoicesByIds({ payload: { ids } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const userInvoices = yield call(adminMiddleware.getUserInvoicesByIds, accessToken, ids);

    yield put(AdminActions.getUserInvoicesByIdsSuccess(userInvoices.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.getUserInvoicesByIdsFail());
  }
}

// patch User Invoice
function* patchUserInvoice({ payload: { id, data } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(adminMiddleware.patchUserInvoice, accessToken, id, data);
    yield put(AdminActions.patchUserInvoiceSuccess(id, data));

  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.patchUserInvoiceFail());
  }
}
// put User Invoice
function* updateUserInvoice({ payload: { document } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    yield call(adminMiddleware.updateUserInvoice, accessToken, document);
    yield put(AdminActions.updateUserInvoiceSuccess());
  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.updateUserInvoiceFail(err?.response?.data));
  }
}


// -------------------------------------- PRINT DOCUMENT -------------------------------------- //

function* printDocument({ payload: { data, queryParams } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);
    const response = yield call(documentMiddleware.printDocument, accessToken, data);

    if (response.data.status === 'error') {
      yield put(AdminActions.printDocumentFail(response.data));
    } else {
      yield put(AdminActions.printDocumentSuccess(response.data));
    }

    // if (data?.reminder) {
    //   yield put(AdminActions.getDocuments(queryParams));
    // }

  } catch (err) {
    if (err.response && err.response.status === 401) {
      return yield put(AuthActions.logout());
    }
    yield put(AdminActions.printDocumentFail(err));
  }
}

// -------------------------------------- FULFILLMENT -------------------------------------- //

function* createFulfillments({ payload: { data } }) {
  try {

    const accessToken = yield select(state => state.auth.access_token);

    const result = yield call(adminMiddleware.createInvoices, accessToken, data);

    yield put(AdminActions.createFulfillmentsSuccess(result.data));
  } catch (err) {
    yield call(tokenErrorHandler, err, AdminActions.createFulfillmentsFail(err));
  }
}

// -------------------------------------- ENTERED PAYMENT  -------------------------------------- //

function* enterPayment({ payload: { paymentValues, paymentStatus, documentId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const res = yield call(adminMiddleware.getUserInvoice, accessToken, documentId, { fields: { entered_payment: true } });

    const { entered_payment } = res.data;

    const currentEnteredPayment = entered_payment && Array.isArray(entered_payment) ? entered_payment : [];
    currentEnteredPayment.push(paymentValues);

    const updateDocument = {
      entered_payment: currentEnteredPayment,
      payment_status: paymentStatus
    };

    const resp = yield call(adminMiddleware.patchUserInvoice, accessToken, documentId, updateDocument);

    yield put(AdminActions.enterPaymentSuccess(paymentValues, paymentStatus, documentId, resp?.data?.history));

  } catch (err) {
    const error = err?.response?.data ?? err?.message;
    yield put(AdminActions.enterPaymentFail(error ?? 'Payment failed'));
  }
}

function* deleteEnteredPayment({ payload: { paymentId, documentId } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const res = yield call(adminMiddleware.getUserInvoice, accessToken, documentId, { fields: { entered_payment: true } });
    const { entered_payment } = res.data;

    if (!entered_payment) {
      throw new Error('Keine eingegebene Zahlung im Dokument gefunden');
    }

    const deletePayment = entered_payment.filter(pay => pay.id !== paymentId);
    const updateDocument = { entered_payment: deletePayment, payment_status: 'open' };

    yield call(adminMiddleware.patchUserInvoice, accessToken, documentId, updateDocument);

    yield put(AdminActions.deleteEnteredPaymentSuccess(paymentId, documentId));

  } catch (err) {
    yield put(AdminActions.deleteEnteredPaymentFail(err?.response));
  }
}



export function* adminSaga() {
  yield takeEvery(ActionTypes.GET_USERS, getUsersSaga);
  yield takeEvery(ActionTypes.GET_USER, getUser);
  yield takeEvery(ActionTypes.UPDATE_USER, updateUser);
  yield takeEvery(ActionTypes.DELETE_USER, deleteUser);
  yield takeEvery(ActionTypes.GET_TEMP_USERS, getTempUsersSaga);
  yield takeEvery(ActionTypes.UPDATE_TEMP_USER, updateTempUser);
  yield takeEvery(ActionTypes.DELETE_TEMP_USERS, deleteTempUser);
  yield takeEvery(ActionTypes.GET_REPORTS_USERS, getReportsUsers);
  yield takeEvery(ActionTypes.GET_USER_COMPANY, getUserCompany);
  yield takeEvery(ActionTypes.GET_ORDER_REPORT, getOrderReport);
  yield takeEvery(ActionTypes.GET_USER_LOG_ENTRIES, getUserLogEntries);
  yield takeEvery(ActionTypes.GET_USERS_CONTACT, getUsersContact);
  yield takeEvery(ActionTypes.GET_USER_INVOICES, getUserInvoices);
  yield takeEvery(ActionTypes.GET_USER_INVOICE_BY_ID, getUserInvoiceById);
  yield takeEvery(ActionTypes.GET_USER_INVOICES_BY_IDS, getUserInvoicesByIds);
  yield takeEvery(ActionTypes.PATCH_USER_INVOICE, patchUserInvoice);
  yield takeEvery(ActionTypes.UPDATE_USER_INVOICE, updateUserInvoice);
  yield takeEvery(ActionTypes.PRINT_DOCUMENT, printDocument);
  yield takeEvery(ActionTypes.CREATE_FULFILLMENT_METHODS, createFulfillments);
  yield takeEvery(ActionTypes.ENTER_PAYMENT, enterPayment);
  yield takeEvery(ActionTypes.DELETE_ENTERED_PAYMENT, deleteEnteredPayment);
}
