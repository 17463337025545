import { API } from 'constants/apiUrl';
import { paginationParams, searchParams } from '../_helpers/query-params';
import { searchKeys, searchKeysContact, searchKeysTemp, logKeys } from './params';



export const adminMiddleware = {

  // ------------------------------------ USER ------------------------------------ //

  getUsers: async function (accessToken, dateRange, { pagination, filter, order, search }) {
    const { license, stat, date_from, date_to, ...rest } = filter;

    const licenses = license ? Object.keys(license).filter((key) => license[key]) : [];
    const statuses = stat ? Object.keys(stat).filter((key) => stat[key]) : [];

    return await API.get(`/users`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            ...((dateRange.date_from && dateRange.date_to) && {
              created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
            }),
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...(filter && {
              ...rest,
              ...(Array.isArray(statuses) && !statuses.includes('all_statuses') && statuses.length > 0 && {
                'status': { inq: statuses },
              }),
              ...(Array.isArray(licenses) && !licenses.includes('all_licenses') && licenses.length > 0 && {
                'license.type': { inq: licenses },
              }),
            }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getUser: async function (accessToken, id) {
    return await API.get(`/user/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getUsersCount: async function (accessToken, dateRange, { pagination, filter, order, search }) {
    const { license, connection, stat, date_from, date_to, usagerep, orderrep, logrep, ...rest } = filter;

    const licenses = license ? Object.keys(license).filter((key) => license[key]) : [];
    const statuses = stat ? Object.keys(stat).filter((key) => stat[key]) : [];
    const connections = connection ? Object.keys(connection).filter((key) => connection[key]) : [];

    return await API.get(`/users/count`, {
      params: {
        where: {
          ...((dateRange.date_from && dateRange.date_to) && {
            created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
          }),
          ...(filter && {
            ...rest,
            ...(Array.isArray(statuses) && !statuses.includes('all_statuses') && statuses.length > 0 && {
              'status': { inq: statuses },
            }),
            ...(Array.isArray(licenses) && !licenses.includes('all_licenses') && licenses.length > 0 && {
              'license.type': { inq: licenses },
            }),
            ...(Array.isArray(connections) && !connections.includes('all_connections') && connections.length > 0 && {
              'marketplace': connections,
            }),
          }),
          ...(search && {
            ...searchParams(searchKeys, search),
          }),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  patchUser: async function (accessToken, id, data) {
    return await API.patch(`/users/${id}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  deleteUser: async function (accessToken, id, data) {
    return await API.post(`/users/${id}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  // ------------------------------------ TEMP USER ------------------------------------ //

  getTempUsers: async function (accessToken, { pagination, filter, order, search }) {
    return await API.get(`/temp-users`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            ...(search && {
              ...searchParams(searchKeysTemp, search),
            }),
            ...(filter && filter),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getTempUsersCount: async function (accessToken, { pagination, filter, order, search }) {
    return await API.get(`/temp-users/count`, {
      params: {
        where: {
          ...(filter && filter),
          ...(search && {
            ...searchParams(searchKeysTemp, search),
          }),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  patchTempUser: async function (accessToken, tempUser) {
    return await API.patch(`/temp-users/${tempUser.id}`, tempUser, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  deleteTempUser: async function (accessToken, ids) {
    return await API.delete(`/temp-users/${ids}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  // ------------------------------------ REPORTS ------------------------------------ //

  getReportsUsers: async function (accessToken, dateRange, { pagination, filter, search, order }) {
    const { license, stat, connection, date_from, date_to, ...rest } = filter;

    const licenses = license ? Object.keys(license).filter((key) => license[key]) : [];
    const statuses = stat ? Object.keys(stat).filter((key) => stat[key]) : [];
    const connections = connection ? Object.keys(connection).filter((key) => connection[key]) : [];

    return await API.get(`/reports-users`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'id DESC'}`],
          where: {
            ...((dateRange.date_from && dateRange.date_to) && {
              created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
            }),
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...(filter && {
              ...rest,
              ...(Array.isArray(statuses) && !statuses.includes('all_statuses') && statuses.length > 0 && {
                'status': { inq: statuses },
              }),
              ...(Array.isArray(licenses) && !licenses.includes('all_licenses') && licenses.length > 0 && {
                'license.type': { inq: licenses },
              }),
              ...(Array.isArray(connections) && !connections.includes('all_connections') && connections.length > 0 && {
                'marketplace': connections,
              }),
            }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  // ------------------------------------ GET USER DETAILS ------------------------------------ //

  getUserCompany: async function (accessToken, id, filter) {
    const usage_filter = filter?.usagerep ?? {};

    return await API.get(`/user-details/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {

        filter: {
          where: {
            ...(usage_filter && {
              // Date range filter
              ...(usage_filter.date_from && usage_filter.date_to) && {
                created_at: { between: [`${usage_filter.date_from}T00:00:00.001Z`, `${usage_filter.date_to}T23:59:59.999Z`] }
              },
            }),
          }
        },

      }
    });
  },


  getOrderReport: async function (accessToken, id, filter) {
    const order_filter = filter?.orderrep ?? {};

    return await API.get(`/order-details/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {

        filter: {
          where: {
            ...(order_filter && {
              // Date range filter
              ...(order_filter.date_from && order_filter.date_to) && {
                created_at: { between: [`${order_filter.date_from}T00:00:00.001Z`, `${order_filter.date_to}T23:59:59.999Z`] }
              },
            }),
          }
        },

      }
    });
  },

  getUserLogEntries: async function (accessToken, id, order, search, filter, page, pageSize) {

    // usage reports ve order reports için olan date filtresini (usage, orderrep) burada ayırmalıyız, rest ile backende gider ve cevabı bozar.
    // stat, user sekmesindeki contextten kalan parametre, onu da çıkartıyoruz yanlışlıkla burada gitmesin.
    const { status, error_filter, success_filter, stat, usagerep, orderrep, logrep, delete: deleteFilter, replace, update, upload, create, archive, category, company, connection, customer, fulfillment, credit, invoice, offer, order: Order, payment, waybill, dashboard, export: Export, amazon, ebay, kaufland, product, digital_product, shipping, file, supplier, support, user, ...rest } = filter;

    const { date_from, date_to } = logrep ?? {};

    const modules_arr = ["archive", "category", "company", "connection", "customer", "fulfillment", "credit", "invoice", "offer", "order", "payment", "waybill", "dashboard", "export", "amazon", "ebay", "kaufland", "product", "digital_product", "shipping", "file", "supplier", "support", "user"];

    const foundModules = modules_arr.filter(module => filter.hasOwnProperty(module));

    return await API.get(`/user-log-entries/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        filter: {
          ...paginationParams(page, pageSize),
          order: [`${order}`],
          where: {
            ...(search && { ...searchParams(logKeys, search) }),

            ...(filter && {

              // Date range filter
              ...(date_from && date_to) && {
                created_at: { between: [`${date_from}T00:00:00.001Z`, `${date_to}T23:59:59.999Z`] }
              },

              // Error or success filter
              ...(error_filter && { "response.error": { "neq": "" } }),
              ...(success_filter && { "response.error": "" }),

              // Method name filter (delete, replace, update, etc.)
              ...(deleteFilter || replace || update || upload || create) && {
                method_name: {
                  like: [
                    ...(deleteFilter ? ['delete'] : []),
                    ...(replace ? ['replace'] : []),
                    ...(update ? ['update'] : []),
                    ...(upload ? ['upload'] : []),
                    ...(create ? ['create'] : []),
                  ].join('|'),
                }
              },

              ...(foundModules.length > 0 && {
                "details.url": {
                  like: foundModules.join("|"),
                  options: 'i'
                },
              }),

              ...rest,

            }),
          },
        },
      }
    });
  },


  // ------------------------------------ GET USERS CONTACT ------------------------------------ //

  getUsersContact: async function (accessToken, { pagination, filter, order, search }) {
    return await API.get(`/users-contact`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'owner ASC'}`],
          where: {
            ...(search && {
              ...searchParams(searchKeysContact, search),
            }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  // ------------------------------------ GET USERS INVOICES ------------------------------------ //

  getUserInvoices: async function (accessToken, dateRange, { pagination, filter, order, search }) {
    const { date_from, date_to, ...rest } = filter;
    return await API.get(`/user-invoices`, {
      params: {
        filter: {
          ...paginationParams(pagination.page, pagination.limit),
          order: [`${order ? order : 'created_at DESC'}`],
          where: {
            ...(search && {
              ...searchParams(searchKeys, search),
            }),
            ...(filter && rest),
            ...((dateRange.date_from && dateRange.date_to) && {
              created_at: { between: [`${dateRange.date_from}T00:00:00.001Z`, `${dateRange.date_to}T23:59:59.999Z`] }
            }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  getUserInvoice: async function (accessToken, id, withFieldParams) {
    return await API.get(`/user-invoice/${id}`, {
      params: {
        filter: {
          ...(withFieldParams === 'field_params' && {
            ...withFieldParams,
          }),
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  patchUserInvoice: async function (accessToken, id, data) {
    return await API.patch(`/user-invoice/${id}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  updateUserInvoice: async function (accessToken, document) {
    return await API.put(`/user-invoice/${document.id}`, document, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  // ------------------------------------ GET USER INVOICE BY ID ------------------------------------ //
  getUserInvoicesByIds: async function (accessToken, ids) {
    return await API.get(`/user-invoices-by-ids`, {
      params: {
        filter: {
          where: {
            ...(ids && { id: { inq: ids } }),
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  createInvoices: async function (accessToken, convertData) {
    return await API.post(`/create-invoices`, convertData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


};
