import React from 'react';
import { UIDates } from '_metronic/_helpers';
import { FormattedMessage } from 'react-intl';


export const initialStatesForReducer = {
  request: {
    loading: false,
    name: null, // request name {create, update, delete ...}
    specify: null, // some pointer {id, sku, ...}
    error: null, // if has error
    status: null, //'start','fulfilled','failed'
  },
  actionController: {
    loading: false,
    error: null,
    result: null, // result of action ["success", "error"]
    id: null, // action id for fetching simplified action
  },
};

const initialAddress = {
  id: '',
  type: '',
  salutation: '',
  name: '',
  company: '',
  address_line1: '',
  address_line2: '',
  address_line3: '',
  post_code: '',
  city: '',
  country_code: '',
  country: '',
  is_default: false
};


// initial position data
export const initialPosition = {
  id: '',
  sku: '',
  title: '',
  url: '',
  tax_rate: 19,
  quantity: 1,
  net_price: 0,
  total_net_price: 0,
  gross_price: 0,
  total_gross_price: 0,
  unit: 'piece',
  currency: 'EUR',
  manuel_product: true,
  connections: [],
  productId: '',
  price_discount: 0,
  //priceBrutto: '0'
};


// initial position Totals
export const initialPositionTotals = {
  sub_total: 0,
  total_tax: 0,
  total: 0,
  shipping_price: 0,
  discount: 0,
  currency: 'EUR',
};

export const initialEnteredValues = {
  date: UIDates.getCurrentDate(),
  payment_method: '',
  notes: '',
  amount: 0,
};



export const initialTax = {
  category: '',
  country_code: '',
  legal_text: '',
};

export const initialPaymentWarnings = {
  type: null,
  amount: 0,
  after_text: 'Sehr geehrte Damen und Herren,',
  before_text: 'Beste Dank.\nBei Problemen oder Fragen stehen wir Ihnen gerne jederzeit zur Verfügung.\nWie wünschen Ihnen noch einen schönen Tag.',
};

// Formik Initial Document
export const initialInvoice = {
  id: '',
  user_id: '',
  invoice_number: '',
  invoice_date: '',

  payment_status: 'open',
  pricing_summary: initialPositionTotals,
  entered_payment: [],
  position: [initialPosition],
  tax: initialTax,

  customer_details: {
    id: '',
    number: 0,
    type: '',
    name: '',
    phone: '',
    email: '',
    company: '',
    mobile: '',
    tax_number: '',
    vat_id: '',
    fax: '',
    notes: '',
    discount: 0,
    invoice_address: initialAddress,
    bank: {
      name: '',
      iban: '',
      bic: ''
    },
    source: {
      buyer_id: '',
      marketplace: ''
    },
  },
  payment_warnings: [],

  subtitle: {
    header: 'Vielen Dank für Ihre Bestellung bei uns.',
    footer: 'Bei Fragen oder Problemen zu Ihrer Bestellung helfen wir Ihnen gerne weiter.Wir wünschen Ihnen einen schönen Tag.'
  },
  notes: '',

};


export const searchKeys = [
  'id',
  'order_id',
  'marketplace',
  'marketplace_url',
  'customer_details.number',
  'customer_details.email',
  'customer_details.phone',
  'customer_details.name',
  'customer_details.company',
  'customer_details.delivery_address.post_code',
];


export const initialSearchParams = {
  searchText: '',
  searchKeys: searchKeys,
};

export const initialQueryParams = { page: 1, limit: 10 };


export const initialDocumentQueryParams = {
  pagination: initialQueryParams,
  search: '',
  filter: {},
  order: 'order_date DESC',
};


export const allQueryKeys = [
  'date_from',
  'date_to',
  'open_invoice',
  'credit_created',
  'non_labeled',
  'search',
  'status',
  'invoice_ebay'
];


export const allFilterKeys = [
  'date_from',
  'date_to',
  'open_invoice',
  'credit_created',
  'non_labeled',
  'invoice_ebay'
];

export const POSITION_NAME = [
  {
    value: "orders",
    label: <FormattedMessage id="DOCUMENT.ORDER_PLURAL" />
  },
  {
    value: "invoices",
    label: <FormattedMessage id="DOCUMENT.INVOICE_PLURAL" />
  },
  {
    value: "manuel_invoices",
    label: <FormattedMessage id="DOCUMENT.MANUAL_INVOICE_TITLE" />
  },
  {
    value: "credits",
    label: <FormattedMessage id="DOCUMENT.CREDIT_PLURAL" />
  },
  {
    value: "waybills",
    label: <FormattedMessage id="DOCUMENT.WAYBILL_PLURAL" />
  },
  {
    value: "offers",
    label: <FormattedMessage id="DOCUMENT.OFFER_TITLE" />
  },
  {
    value: "products",
    label: <FormattedMessage id="PRODUCT.TITLE" />
  },
  {
    value: "customers",
    label: <FormattedMessage id="CUSTOMER.TITLE_PLURAL" />
  },
  {
    value: "suppliers",
    label: <FormattedMessage id="PRODUCT.GENERAL.SUPPLIER" />
  },
  {
    value: "transactions",
    label: <FormattedMessage id="GENERAL.TRANSACTION" />
  },
  {
    value: "shippings",
    label: <FormattedMessage id="GENERAL.LABEL" />
  },
  {
    value: "connections",
    label: <FormattedMessage id="MENU.ASIDE.CONNECTIONS" />
  }
];


export const getPositionLabel = (name) => {
  return POSITION_NAME.find(item => item.value === name)?.label
}


