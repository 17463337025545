import React, { useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { uiGetMwstTextByTaxCategory, getLastSearch } from '_metronic/_helpers';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import * as uiHelpers from '../../../modules/documents/_context/DocumentsUIHelpers';
import { useUserInvoicesUIContext } from '../_context/UserInvoicesUIContext';
import { UserGuideHelper } from '../../../user-guides/UserGuideHelper';
import { BackButton, SaveButton } from '_metronic/_partials';
import { PositionsCard } from './positions/PositionsCard';
import { AdminActions } from 'app/admin/_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { AdminRoutes } from 'constants/moduleRoutes';
import { CustomerCard } from './customer/CustomerCard';
import { AddressCard } from './address/AddressCard';
import { PaymentCard } from './payment/PaymentCard';
import { FormikHandler } from './FormikHandler';
import { Col, Row } from 'react-bootstrap';
import { TextCard } from './text/TextCard';
import { History } from './history/History';
import { toast } from 'react-toastify';
import { isNumber } from 'lodash';




export function UserInvoicesEdit({ history, match: { params: { id, copyId, convertedDocumentId, convertedDocumentUrl } } }) {

  const dispatch = useDispatch();
  const lastSearch = getLastSearch()

  const { formatMessage: intl } = useIntl()
  const UIDocContext = useDocumentsUIContext();
  const UIContext = useUserInvoicesUIContext();
  const UIProps = useMemo(() => ({
    setupRequestedDocument: UIDocContext.setupRequestedDocument,
    requestedDocument: UIDocContext.requestedDocument,
    // queryParams: UIContext.queryParams,
    // setPaginationQuery: UIContext.setPaginationQuery,
    // setOrderQuery: UIContext.setOrderQuery,
    // setSearchQuery: UIContext.setSearchQuery,
    // setFilterQuery: UIContext.setFilterQuery,
    // dateRange: UIContext.dateRange,
    // ids: UIContext.ids,
    // setIds: UIContext.setIds,
    // openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
    // closePrintDocumentDialog: UIContext.closePrintDocumentDialog,
    // openDocumentDetailsDialog: UIContext.openDocumentDetailsDialog,
    handleOpenEnterPaymentEditDialog: UIContext.handleOpenEnterPaymentEditDialog,
  }), [UIContext, UIDocContext]);

  const { userInvoiceForEdit, positions, loading, request,success } = useSelector(state => ({
    // documentActionLoading: state.documents.actionLoading,
    loading: state.admin.loading,
    userInvoiceForEdit: state.admin.userInvoiceForEdit,
    positions: state.admin.userInvoiceForEdit.position,
    request: state.admin.request,
    success: state.admin.success,
    // documentSettingsForEdit: state.documents.userSettings,
    // customerId: state.documents?.customer?.id,
    //tax: state.documents.userSettings?.default_product?.tax
    //  ? +state.documents.settings.default_product.tax
    //  : 19, 
  }),
    shallowEqual
  );

  // const isLoading = useLoadingHandler(loading, AdminActions.clearLoadingEffects());
  useEffect(() => {
    id &&
      dispatch(AdminActions.getUserInvoiceById(id));

  }, [id, dispatch]);


  useEffect(() => {
    if (request.status === 'fulfilled') {
      // if (UIProps.showLabelSettingsDialog) {
      //   UIProps.setDocumentId(request.documentId);
      //   return history.replace(`${AdminRoutes.ADMIN_USER_DOCUMENT}/${UIProps.requestedDocument.type}/${request.documentId}/edit`)
      // }
      
      return history.push(`${AdminRoutes.ADMIN_USER_DOCUMENT}/${UIProps.requestedDocument.type + lastSearch}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UIProps.requestedDocument.type, history, request.error, request.status, lastSearch]);


  // Save Document
  const saveDocument = (values) => {
    
    const { selectedCustomerAddressId, customer_details, payment_warnings, history, ...restValues } = values;

    // --------------------------------- Toast Message ---------------------------------
    const overPayment = uiHelpers.paymentAmountDifference(positions, values)

    if (overPayment < 0) {
      return UIProps.openOverPaymentInfoDialog(-overPayment, positions[0]?.currency)
    }
    // const formattedMessageId = uiHelpers.documentToastMessage(customer_details, positions)
    // if (formattedMessageId) {
    //   return toast.warning(intl({ id: formattedMessageId }))
    // }
    for (const pos of positions) {
      if (!isNumber(pos.quantity)) {
        return toast.warning(<FormattedMessage id='DOCUMENT.EDIT.QUANTITY.NOT_NUMBER' values={{ sku: pos.sku }} />);
      }
    }

    // ---------------------------  Total Net and Brutto Price ---------------------------
    const netTotal = positions.reduce((acc, net) => acc + +net.total_net_price, 0);
    const bruttoTotal = positions.reduce((acc, net) => acc + +net.total_gross_price, 0);
    //const calculateTaxes = taxCalculator(positions, shippingPrice);
    //const totalTaxPrice = calculateTaxes.reduce((acc, arr) => acc + arr.mwst, 0);

    // ---------------------------  Document Preparation ---------------------------
    const document = {
      ...restValues,
      customer_details,
      position: Array.isArray(positions) ? positions : [],
      tax: {
        ...values.tax,
        country_code: values?.customer_details?.delivery_address?.country_code,
        legal_text: uiGetMwstTextByTaxCategory(values.tax?.category),
      },
      pricing_summary: {
        ...values.pricing_summary,
        total: +bruttoTotal,
        sub_total: +netTotal,
        total_tax: +bruttoTotal - +netTotal,
        currency: values.marketplace !== "" ? values.pricing_summary.currency : positions[0]?.currency,
      },
    };

    if (id) {
      dispatch(AdminActions.updateUserInvoice(document));
    }
  };

  const btnRef = useRef();
  const resetBtnRef = useRef();
  const saveDocumentClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const pageTitle = () => {
    let message
    if (id) {
      message = "DOCUMENT.UPDATE_TITLE"
    } else {
      message = UIProps.requestedDocument.type === 'offers' ? "DOCUMENT.NEUES" : UIProps.requestedDocument.type === 'waybills' ? "DOCUMENT.NEUER" : "DOCUMENT.NEW"
    }
    return intl({ id: message }, { name: UIProps.requestedDocument.title })
  }


  return (
    <>

      {/* {documentActionLoading && <LayoutSplashScreen />} */}


      {/* <CustomerEdit show={UIProps.showCustomerEditDialog} onHide={UIProps.handleCloseCustomerEditDialog} modal /> */}

      <Card className="card-box">

        <CardHeader
          back={<BackButton link={`${AdminRoutes.ADMIN_USER_DOCUMENT}/${UIProps.requestedDocument.type + lastSearch}`} />}
          title={pageTitle()}
          info={<UserGuideHelper title={UIProps.requestedDocument.title} />}
          id={`title_document_${id ? 'update' : 'add'}`}
          sticky
        >
          <CardHeaderToolbar>
            <SaveButton onClick={saveDocumentClick} id={UIProps.requestedDocument.title} />
          </CardHeaderToolbar>
        </CardHeader>

        <CardBody>

          <FormikHandler
            autoComplete="on"
            userInvoiceForEdit={userInvoiceForEdit}
            btnRef={btnRef}
            resetBtnRef={resetBtnRef}
            saveDocument={saveDocument}
            id={id}
            success={success}
            onClose={() => {
              history.push(`${AdminRoutes.ADMIN_USER_DOCUMENT}/${UIProps.requestedDocument?.type + lastSearch}`);
              dispatch(
                AdminActions.cleanUpUserModuleParams({
                  userInvoiceForEdit: uiHelpers.initialDocument,
                })
              );
            }}
          >


            <>
              <Row>
                <Col md={6} className='mb-md-0 mb-sm-8'>
                  <CustomerCard loading={loading} />
                </Col>

                <Col md={6} className='mb-md-0 mb-sm-8'>
                  <AddressCard loading={loading} />
                </Col>
              </Row>

              <PositionsCard loading={loading} />

              <PaymentCard loading={loading} documentId={id} handleOpenEnterPaymentEditDialog={UIProps.handleOpenEnterPaymentEditDialog} />

              <Row>
                <Col md={6} >
                  <TextCard loading={loading} documentId={id} />
                </Col>

                <Col md={6}>
                  <History history={userInvoiceForEdit?.history} />
                </Col>
              </Row>

            </>


          </FormikHandler>

        </CardBody>

        {/* <CreationAndUpdateInfo data={userInvoiceForEdit} />

        <StockControlDialog show={stockControl.show} onHide={() => setStockControl({ ...stockControl, show: false })} data={stockControl.data} />

        <SendEmailDialog />

        <OverPaymentInfoDialog /> */}

      </Card>
    </>
  );
}