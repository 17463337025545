import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ContractSaveDialog from '../contract-dialogs/ContractSaveDialog';
import { useContractUIContext } from '../_context/ContractUIContext';
import { INITIAL_CONTRACT } from '../_context/ContractUIHelpers';
import { SettingsActions } from '../../_redux/actions';
import { AdminRoutes } from 'constants/moduleRoutes'
import { Content } from './Content';
import Info from './Info';



export function ContractEdit({ contractBtnRef, history, id }) {

  const dispatch = useDispatch();

  const contracts = useSelector(state => state.adminSettings?.contracts);
  const contract = contracts?.find(con => con.id === id) || INITIAL_CONTRACT;

  const [showModal, setShowModal] = useState(false);
  const [editedContract, setEditedContract] = useState(contract);


  const UIContext = useContractUIContext();
  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
  }), [UIContext]);

  useEffect(() => {
    id && dispatch(SettingsActions.getContracts(UIProps.queryParams))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  const handleSaveModal = (call) => {
    if (call === "save") {
      dispatch(SettingsActions.updateContract(editedContract));
    } else if (call === "new") {
      dispatch(SettingsActions.createContract(editedContract));
    }
    setShowModal(false);
    history.push(AdminRoutes.SETTINGS_CONTRACT)
  }

  const handleSubmit = (data) => {
    setEditedContract(data);
    setShowModal(true);
  }


  return (
    <div className="d-flex flex-column flex-xl-row">

      <Content initialValues={contract} contractBtnRef={contractBtnRef} handleSubmit={handleSubmit} contract={contract} />

      <Info contract={contract} />

      <ContractSaveDialog showModal={showModal} contract={contract} history={history} handleSaveModal={handleSaveModal} />

    </div>
  )
}
