import React from 'react';
import { ContractUIProvider } from './_context/ContractUIContext'
import { ContractCard } from './ContractCard';



export function ContractSettings() {

  return (
    <>
      <ContractUIProvider>
        <ContractCard />
      </ContractUIProvider>
    </>
  );
}
