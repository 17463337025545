import React from "react";
import { Formik, Form } from 'formik';
import { Card, CardBody, CardSubHeader } from "_metronic/_partials/controls";
import { CkEditor } from "_metronic/_partials/controls";
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function Content({ initialValues, handleSubmit, contractBtnRef, contract }) {
  return (
    <Card className="flex-row-fluid gutter-b">

      <CardSubHeader
        title={contract.type === "privacy" ? <FormattedMessage id='AUTH.GENERAL.PRIVACY' /> : <FormattedMessage id='AUTH.GENERAL.TERMS' />}
        icon={<Icon.FileEarmarkMedical />}
        id="description"
      />

      <CardBody>
        <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <CkEditor name="contract" data={values.content}
                onBlur={(e, editor) => {
                  setFieldValue("content", editor.getData());
                }}
                size="calc(100vh - 350px)"
              />

              <button type="submit" className="d-none" ref={contractBtnRef} />

            </Form>
          )}
        </Formik>
      </CardBody>

    </Card>
  );
}
