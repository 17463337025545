import React, { createContext, useCallback, useContext, useState } from 'react';
import { UIDates } from '_metronic/_helpers';
import { useIntl } from 'react-intl';
import { useSearchQuery } from 'app/hooks';
import { initialEnteredValues } from './UserInvoicesUIHelpers';
import moment from 'moment';



const UserInvoicesUIContext = createContext();
export const useUserInvoicesUIContext = () => useContext(UserInvoicesUIContext);

export const UserInvoicesUIProvider = ({ children }) => {

  const { formatMessage: intl } = useIntl()
  const [ids, setIds] = useState([]);
  const [userId, setUserId] = useState();
  const [documentId, setDocumentId] = useState();


  // query params
  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'created_at DESC',
    search: '',
    filter: { status: 'active' },
  });

  const setQueryParams = useCallback((params) => setQueryBase(params), [])

  const { setSearchQuery, setFilterQuery, setPaginationQuery, setOrderQuery } = useSearchQuery(setQueryParams);

  const initialDateRange = {
    date_from: UIDates.dayCalculation(-30),
    date_to: UIDates.getCurrentDate(),
    label: intl({ id: "DASHBOARD.DATE.THIS_MONT" }),
  };

  const [dateRange, setDateRange] = useState(
    initialDateRange
  );

  const changeDateRange = (date = initialDateRange) => setDateRange(date);


  const [requestedDocument, setRequestedModule] = useState(null);


  const setupRequestedDocument = (document) => {
    setRequestedModule({
      ...document,
      title: intl({ id: document.title }),
      plural: intl({ id: document.plural }),
    });
    setIds([]);
  };

  // Convert Document
  const [showConvertDocumentsDialog, setShowConvertDocumentsDialog] = useState(false);
  const openConvertDocumentsDialog = () => setShowConvertDocumentsDialog(true);
  const closeConvertDocumentsDialog = () => setShowConvertDocumentsDialog(false);

  //* Document details dialog
  const [showDocumentDetailsDialog, setShowDocumentDetailsDialog] = useState(false);
  const openDocumentDetailsDialog = id => {
    setDocumentId(id);
    setShowDocumentDetailsDialog(true);
  };
  const closeDocumentDetailsDialog = () => {
    setDocumentId(undefined);
    setShowDocumentDetailsDialog(false);
  };

  // Print
  const [showPrintDocumentDialog, setShowPrintDocumentDialog] = useState(false);
  const openPrintDocumentDialog = () => {
    setShowPrintDocumentDialog(true);
  };
  const closePrintDocumentDialog = () => {
    setShowPrintDocumentDialog(false)
  };

  // Export
  const [showExportDocumentDialog, setShowExportDocumentDialog] = useState(false);
  const openExportDocumentDialog = (id) => {
    setShowExportDocumentDialog(true);
    setDocumentId(id);
  };
  const closeExportDocumentDialog = () => {
    setShowExportDocumentDialog(false)
    setDocumentId(null);
  };

  //* Send_Email_Dial0g
  const [showSendEmailsDialog, setShowSendEmailsDialog] = useState(false);
  const handleOpenSendEmailsDialog = () => {
    setShowSendEmailsDialog(true);
  };
  const handleCloseSendEmailsDialog = () => {
    setShowSendEmailsDialog(false);
  };

  //* -------------------------------- ENTERED PAYMENT -------------------------------- *//

  const [enteredPaymentForEdit, setEnteredPaymentForEdit] = useState(initialEnteredValues);
  const [showEnterPaymentEditDialog, setShowEnterPaymentEditDialog] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const handleCloseEnterPaymentEditDialog = () => {
    setEnteredPaymentForEdit(initialEnteredValues);
    setShowEnterPaymentEditDialog(false);
    setSelectedPaymentId(null);
  };
  const handleOpenEnterPaymentEditDialog = () => {
    setShowEnterPaymentEditDialog(true);
    // setSelectedPaymentId(null);
  };
  const handleOpenEditEnterPaymentEditDialog = values => {
    setSelectedPaymentId(values.id);
    setShowEnterPaymentEditDialog(true);
    setEnteredPaymentForEdit({
      ...values,
      date: moment(values.date, 'YYYYY-MM-DDT00:00:00.000Z').format('YYYY-MM-DD'),
    });
  };

  const [showEnterPaymentDialog, setShowEnterPaymentDialog] = useState(false);

  const handleCloseEnterPaymentDialog = () => {
    setShowEnterPaymentDialog(false);
  }

  const openEnterPaymentDialog = (id) => {
    setSelectedPaymentId(id);
    setShowEnterPaymentDialog(true);
  }


  // Over Payment Info Dialog
  // const [showOverPaymentInfoDialog, setShowOverPaymentInfoDialog] = useState({ show: false, price: 0, currency: '' });
  // const openOverPaymentInfoDialog = (price, currency) => setShowOverPaymentInfoDialog({ show: true, price, currency });
  // const closeOverPaymentInfoDialog = () => setShowOverPaymentInfoDialog({ show: false, price: 0, currency: '' });


  const value = {
    ids,
    userId,
    documentId,
    setDocumentId,
    setUserId,
    setIds,
    queryParams,
    setSearchQuery,
    setFilterQuery,
    setPaginationQuery,
    setOrderQuery,
    changeDateRange,
    dateRange,
    setDateRange,
    initialDateRange,

    showConvertDocumentsDialog,
    openConvertDocumentsDialog,
    closeConvertDocumentsDialog,

    showDocumentDetailsDialog,
    openDocumentDetailsDialog,
    closeDocumentDetailsDialog,

    showPrintDocumentDialog,
    openPrintDocumentDialog,
    closePrintDocumentDialog,

    showExportDocumentDialog,
    openExportDocumentDialog,
    closeExportDocumentDialog,

    showSendEmailsDialog,
    handleOpenSendEmailsDialog,
    handleCloseSendEmailsDialog,

    showEnterPaymentEditDialog,
    enteredPaymentForEdit,
    selectedPaymentId,
    setSelectedPaymentId,
    handleCloseEnterPaymentEditDialog,
    handleOpenEnterPaymentEditDialog,
    handleOpenEditEnterPaymentEditDialog,

    showEnterPaymentDialog,
    openEnterPaymentDialog,
    handleCloseEnterPaymentDialog,


    requestedDocument,
    setupRequestedDocument,

  };

  return (
    <UserInvoicesUIContext.Provider value={value}>
      {children}
    </UserInvoicesUIContext.Provider>
  );
}
