import React from 'react';
import { useDispatch } from 'react-redux';
import { footerLength, headerLength } from 'app/modules/settings/document-settings/Text';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import {SVFormTextarea } from '_metronic/_partials/controls';
import { AdminActions } from 'app/admin/_redux/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '_metronic/_icons';
import * as Yup from 'yup';




const maxValidation = (name) => <FormattedMessage id="GENERAL.MAX" values={{ name }} />

export function TextEditDialog({ show, onHide, title, loading, documentId }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const { values } = useFormikContext();
  const { id, notes } = values;
  const TextEditSchema = Yup.object().shape({
    subtitle: Yup.object().shape({
      header: Yup.string()
        .max(headerLength, maxValidation(headerLength)),
      footer: Yup.string()
        .max(footerLength, maxValidation(footerLength)),
    }),
    notes: Yup.string()
      .max(1000, maxValidation("1000")),

  });

  const onSubmit = (values) => {
    const { notes, subtitle } = values

    const data = {
      notes,
      subtitle
    }

    dispatch(AdminActions.patchUserInvoice(id, data));
    onHide();
  };

  const INPUTS = [
    {
      name: "subtitle.header",
      label: "GENERAL.HEADER",
    },
    {
      name: "subtitle.footer",
      label: "GENERAL.FOOTER",
    },
    {
      name: "notes",
      label: "DOCUMENT.HEADER_COURSE",
      feedbackLabel: notes?.length
        ? <span className={`${notes && notes?.length <= (1000 * 80 / 100) ? 'text-success' : notes && notes?.length <= 1000 ? 'text-warning' : 'text-danger'}`}>
          <FormattedMessage id="SETTINGS.DOCUMENT.REMAINING_CHARACTER" values={{ character: 1000 - notes?.length }} />
        </span>
        : true,
      // maxLength: 1000,
    },
  ];

  return (
    <Modal size="md" show={show} onHide={onHide} keyboard={false} aria-labelledby="example-modal-sizes-title-lg" centered>

      <Modal.Header>
        <Modal.Title>
          <Icon.GeoAlt className="mr-3" />
          <FormattedMessage id={title} />
        </Modal.Title>
      </Modal.Header>

      <Formik enableReinitialize={true} initialValues={values} validationSchema={TextEditSchema} onSubmit={onSubmit}>
        <Form>

          <Modal.Body>
            <Row>

              {INPUTS.map((item, i) => (!(item.name === "notes" && !documentId) &&
                <Col lg={12} key={i}>
                  <Field component={SVFormTextarea}
                    {...{
                      name: item.name,
                      label: intl({ id: item.label }),
                      placeholder: item.placeholder,
                      rows: 4,
                      maxLength: item.maxLength,
                      withFeedbackLabel: true,
                      feedbackLabel: item.feedbackLabel || true,
                      disabled: loading,
                    }}
                  />
                </Col>
              ))}

            </Row>
          </Modal.Body>

          <Modal.Footer>

            <Button variant='light' onClick={onHide} id="btn_cancel_modal">
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>

            <Button variant='primary' type="submit" id='btn_update_modal'>
              <FormattedMessage id="GENERAL.UPDATE" />
            </Button>

          </Modal.Footer>

        </Form>
      </Formik>

    </Modal>
  );
}