import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, CardBody, CardHeaderToolbar, CardSubHeader } from '_metronic/_partials/controls';
import { TAX_CATEGORY } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { useFormikContext } from 'formik';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { CustomerEditDialog } from './CustomerEditDialog';
import { CardItems } from '_metronic/_partials/components';



export function CustomerCard({ loading }) {

  const { formatMessage: intl } = useIntl();

  const [show, setShow] = useState({ customerShow: false });
  const customerEdit = () => setShow({ ...show, customerShow: true });

  const { values: { customer_details: { name, mobile, phone, company, email, vat_id }, tax } } = useFormikContext();

  const CUSTOMER_INFO = [
    (name &&
    {
      label: "GENERAL.NAME",
      content: name,
    }
    ),
    (email &&
    {
      label: "GENERAL.EMAIL",
      content: email,
    }
    ),
    (company &&
    {
      label: "GENERAL.COMPANY",
      content: company,
    }),
    (mobile &&
    {
      label: "GENERAL.MOBILE",
      content: mobile,
    }),
    (phone &&
    {
      label: "GENERAL.PHONE",
      content: phone,
    }),
    (vat_id &&
    {
      label: "GENERAL.US_NUMBER",
      content: vat_id,
    }),
    (tax.category &&
    {
      label: "DOCUMENT.PAYMENT_CATEGORY",
      content: intl({ id: TAX_CATEGORY.find(item => item.value === tax.category)?.label }),
    })

  ].filter(Boolean);


  return (<>

    {(show.customerShow) &&
      <CustomerEditDialog
        show={show.customerShow}
        onHide={() => setShow({ ...show, customerShow: false })}
        loading={loading}
      />
    }

    <Card className="card-stretch gutter-b">

      <CardSubHeader icon={<Icon.Person />} title={<FormattedMessage id='DOCUMENT.CUSTOMER.TITLE' />}>
        <CardHeaderToolbar>
          <OverlayTrigger overlay={<Tooltip id="document-print-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
            <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={customerEdit} disabled={loading} id="btn_edit_address">
              <Icon.PencilSquare />
            </Button>
          </OverlayTrigger>
        </CardHeaderToolbar>
      </CardSubHeader>


      <CardBody>
        <CardItems Content={CUSTOMER_INFO} />
      </CardBody>

    </Card>
  </>);
}