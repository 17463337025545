import React from 'react';
import { Link } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import { NoRecordsFoundMessage } from '_metronic/_helpers';
import { sortCaret, headerSortingClasses } from '_metronic/_helpers';
import { ActionsColumnFormatter } from './ActionsColumnFormatter';
import { RemotePagination } from '_metronic/_partials/controls';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { AdminRoutes } from "constants/moduleRoutes";
import { UIDates } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';



export function ContractTable({ contracts, contractCount, loading, UIProps }) {

  const { formatMessage: intl } = useIntl();

  const COLUMNS = [
    {
      dataField: 'type',
      text: intl({ id: 'ADDRESS.TABLE.TITLE_TYPE' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '150px' },
      headerClasses: 'text-left text-nowrap',
      classes: 'text-left pl-0',
      formatter: (cell, row) => {
        return (
          <div className='d-flex align-items-center'>

            <OverlayTrigger overlay={<Tooltip id="language-panel-tooltip" className='font-weight-bolder'><FormattedMessage id={'SETTINGS.CONTRACT_TITLE'} /></Tooltip>}>
              <div className="symbol symbol-40 mr-2">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-lg">
                    <Icon.FileEarmarkMedical />
                  </span>
                </div>
              </div>
            </OverlayTrigger>

            <div>
              <Link to={AdminRoutes.SETTINGS_CONTRACT_EDIT_FN(row.id)} type="button" className="d-flex align-items-center text-decoration-none text-dark text-hover-primary text-nowrap preview" id='btn_contract_edit'>
                <span className='font-size-lg font-weight-bold'>
                  {row.type === "privacy" ? intl({ id: 'AUTH.GENERAL.PRIVACY' }) : intl({ id: 'AUTH.GENERAL.AGB' })}
                </span>
                <span className='d-flex text-dark-25 svg-icon svg-icon-sm invisible pl-2 pb-1'>
                  <Icon.PencilSquare />
                </span>
              </Link>

              <div type="button" className="d-flex align-items-center text-nowrap preview" onClick={() => navigator.clipboard.writeText(row.id)} id='btn_contract_edit'>
                <samp className='font-weight-bold text-dark-50 text-hover-primary'>
                  {row.id}
                </samp>
                <span className='d-flex invisible text-dark-25 svg-icon svg-icon-sm pl-2'>
                  <Icon.Clipboard />
                </span>
              </div>
            </div>

          </div>
        )
      },
    },
    {
      dataField: 'tags',
      text: intl({ id: 'GENERAL.FILTER.TAGS' }),
      classes: 'text-center text-nowrap',
      headerClasses: 'text-center',
      headerStyle: { minWidth: '150px' },
      formatter: (cell, row) => {
        if (!cell) return null;
        return cell.map((item, i) => (
          <div key={i} className={`label label-inline label-outline-info mr-2`}>
            <span className="font-weight-bold text-nowrap">{item}</span>
          </div>
        ))
      }
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'SETTINGS.CREATED_AT' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'text-center',
      headerClasses: 'text-center text-nowrap',
      formatter: (cell, row) => (
        <div className='font-size-sm text-dark-50 font-weight-bold'>
          {UIDates.formatDateTime(cell)}
        </div>
      ),
    },
    {
      dataField: 'updated_at',
      text: intl({ id: 'SETTINGS.UPDATED_AT' }),
      sort: true,
      sortCaret,
      onSort: UIProps.setOrderQuery,
      headerSortingClasses,
      classes: 'text-center',
      headerClasses: 'text-center text-nowrap',
      formatter: (cell, row) => (
        <div className='font-size-sm text-dark-50 font-weight-bold'>
          {UIDates.formatDateTime(cell)}
        </div>
      ),
    },
    {
      dataField: 'action',
      text: "...",
      headerClasses: 'font-size-h3 text-center pt-0',
      classes: 'text-center',
      headerStyle: { minWidth: '60px' },
      formatter: ActionsColumnFormatter,
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        data={contracts || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center"
        bootstrap4
        striped
        bordered={false}
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => UIProps.setSearchQuery('')} />}
      />
      <RemotePagination
        queryParams={UIProps.queryParams.pagination}
        setQueryParams={UIProps.setPaginationQuery}
        totalCount={contractCount || 0}
        entitiesCount={contracts.length || 0}
        loading={!!loading}
      />
    </>
  );
}
