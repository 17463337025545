import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardBody, Card, CardSubHeader } from '_metronic/_partials/controls';
import { useContractUIContext } from './_context/ContractUIContext';
import { ContractTable } from './contract-table/ContractTable';
import { LoadingDialog } from "_metronic/_partials/loading";
import { SettingsActions } from '../_redux/actions';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function ContractCard() {

  const dispatch = useDispatch();

  const UIContext = useContractUIContext();

  const UIProps = useMemo(() => ({
    queryParams: UIContext.queryParams,
    setOrderQuery: UIContext.setOrderQuery,
    setPaginationQuery: UIContext.setPaginationQuery,
    setSearchQuery: UIContext.setSearchQuery,
    ids: UIContext.ids,
    setIds: UIContext.setIds,
  }), [UIContext]);

  const { count, loading, contracts } = useSelector(state => state.adminSettings);

  useEffect(() => {
    dispatch(SettingsActions.getContracts(UIProps.queryParams));
  }, [dispatch, UIProps.queryParams]);


  return (
    <Card className='card-height'>

      <CardSubHeader
        icon={<Icon.FileEarmarkMedical />}
        title={<FormattedMessage id='SETTINGS.CONTRACTS_TITLE' />}
        id='title_contract'
      />

      <CardBody>

        <ContractTable contracts={contracts} loading={loading} contractCount={count} UIProps={UIProps} />

      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  );
}
