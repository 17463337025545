import { API } from 'constants/apiUrl';
import { searchKeysContract } from './params';
import { paginationParams, searchParams } from './query-params';



export const settingsMiddleware = {

  create: async function (accessToken, userSettings) {
    return await API.post(`/user-settings`, userSettings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  get: async function (accessToken) {
    return await API.get(`/user-settings`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getCount: async function (accessToken, settings_type) {
    return await API.get(`${settings_type}/count`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getById: async function (accessToken, settingId) {
    return await API.get(`/user-settings/${settingId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  getByType: async function (accessToken, settings_type) {
    return await API.get(`/user-settings`, {
      params: {
        filter: {
          where: {
            settings_type,
          },
        },
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  update: async function (accessToken, id, settings) {
    return await API.put(`/user-settings/${id}`, settings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  patchUserSettings: async function (accessToken, id, settings) {
    return await API.patch(`/user-settings/${id}`, settings, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },


  delete: async function (accessToken, settingsId) {
    return await API.delete(`/user-settings/${settingsId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  // Admin tarafındaki settings
    // ------------------------------------ CONTRACTS ------------------------------------ //
  
    getContracts: async function (accessToken, { pagination, filter, order, search }) {
      return await API.get(`/contracts`, {
        params: {
          filter: {
            ...paginationParams(pagination.page, pagination.limit),
            order: [`${order ? order : 'id DESC'}`],
            where: {
              ...(search && {
                ...searchParams(searchKeysContract, search),
              }),
              ...(filter && filter),
            },
          },
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
  
  
    getContractsCount: async function (accessToken, { pagination, filter, order, search }) {
      return await API.get(`/contracts/count`, {
        params: {
          where: {
            ...(filter && filter),
            ...(search && {
              ...searchParams(searchKeysContract, search),
            }),
          },
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
  
  
    createContract: async function (accessToken, contract) {
      return await API.post(`/contracts`, contract, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },


    patchContract: async function (accessToken, contract) {
      return await API.patch(`/contracts/${contract.id}`, contract, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },

};
