import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { ContractEdit } from './contract-settings/contract-edit/ContractEdit'
import { ContractSettings } from './contract-settings/ContractSettings';
import { DocumentSettings } from './document-settings/DocumentSettings';
import { CompanyEdit } from './company/company-edit/CompanyEdit';
import { CancelButton, SaveButton } from '_metronic/_partials';
import LoginSettings from './login-settings/LoginSettings';
import { FormattedMessage, useIntl } from 'react-intl';
import { AdminRoutes } from 'constants/moduleRoutes';
import { CompanyCard } from './company/CompanyCard';
import { ContentRoute } from '_metronic/layout';
import SettingsMenu from './SettingsMenu';
import { Icon } from '_metronic/_icons';



export default function SettingsCard() {

  const { formatMessage: intl } = useIntl();
  const { pathname } = useLocation();
  const history = useHistory();

  const id = window.location.pathname?.split("/")?.[4];

  const { loading } = useSelector(state => ({
    loading: state.settings.loading,
  }));


  // Company settings
  const companyBtnRef = useRef();
  const saveCompany = () => companyBtnRef.current?.click();

  // Document settings
  const docBtnRef = useRef();
  const saveDocument = () => docBtnRef.current?.click();

  // Document settings
  const contractBtnRef = useRef();
  const saveContract = () => contractBtnRef.current?.click();


  const SETTINGS_MENU = [
    {
      name: "company",
      title: "COMPANY.TITLE",
      route: AdminRoutes.SETTINGS_COMPANY,
      toolbar: <Link to={AdminRoutes.SETTINGS_COMPANY_EDIT} className='btn btn-primary svg-icon svg-icon-sm font-weight-bold' disabled={loading}>
        <FormattedMessage id="GENERAL.EDIT" />
      </Link>,
    },
    {
      name: "company_edit",
      title: "COMPANY.EDIT.TITLE",
      route: AdminRoutes.SETTINGS_COMPANY_EDIT,
      toolbar:
        <>
          <CancelButton onClick={() => history.push(AdminRoutes.SETTINGS_COMPANY)} disabled={loading} />
          <SaveButton companyBtnRef={companyBtnRef} onClick={saveCompany} disabled={loading} />
        </>
    },
    {
      name: "login",
      title: "AUTH.GENERAL.ACCOUNT_SETTINGS",
      route: AdminRoutes.SETTINGS_LOGIN,
    },
    {
      name: "invoices",
      title: "DOCUMENT.INVOICE_TITLE",
      route: AdminRoutes.SETTINGS_DOCUMENT_FN('invoices'),
      toolbar: <SaveButton docBtnRef={docBtnRef} onClick={saveDocument} disabled={loading} />
    },
    {
      name: "orders",
      title: "DOCUMENT.ORDER_TITLE",
      route: AdminRoutes.SETTINGS_DOCUMENT_FN('orders'),
      toolbar: <SaveButton docBtnRef={docBtnRef} onClick={saveDocument} disabled={loading} />
    },
    {
      name: "credits",
      title: "DOCUMENT.CREDIT_TITLE",
      route: AdminRoutes.SETTINGS_DOCUMENT_FN('credits'),
      toolbar: <SaveButton docBtnRef={docBtnRef} onClick={saveDocument} disabled={loading} />
    },
    {
      name: "contracts",
      title: "SETTINGS.CONTRACT_TITLE",
      route: AdminRoutes.SETTINGS_CONTRACT_EDIT_FN(id),
      toolbar:
        <>
          <CancelButton onClick={() => history.push(AdminRoutes.SETTINGS_CONTRACT)} disabled={loading} />
          <SaveButton contractBtnRef={contractBtnRef} onClick={saveContract} disabled={loading} />
        </>
    },
  ];

  const page = SETTINGS_MENU.find(item => pathname === item.route);


  return (
    <Card className="card-box">

      <CardHeader
        icon={<Icon.Settings />}
        title={intl({ id: "SETTINGS.TITLE" })}
        className="display-5"
        id='title_settings'
        sticky
      >
        <CardHeaderToolbar>
          {page?.toolbar}
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody className="d-flex flex-column flex-md-row">

        <div className="flex-md-row-auto w-md-200px mr-md-8">
          <SettingsMenu />
        </div>

        <div className="flex-row-fluid">
          <Switch>

            <Redirect exact={true} from={AdminRoutes.SETTINGS} to={AdminRoutes.SETTINGS_COMPANY} />

            <ContentRoute exact path={AdminRoutes.SETTINGS_COMPANY} component={CompanyCard} />

            <ContentRoute exact path={AdminRoutes.SETTINGS_COMPANY_EDIT}>
              <CompanyEdit companyBtnRef={companyBtnRef} onClick={saveCompany} />
            </ContentRoute>

            <ContentRoute exact path={AdminRoutes.SETTINGS_DOCUMENT}>
              <DocumentSettings docBtnRef={docBtnRef} />
            </ContentRoute>

            <ContentRoute exact path={AdminRoutes.SETTINGS_LOGIN} component={LoginSettings} />

            <ContentRoute exact path={AdminRoutes.SETTINGS} component={CompanyCard} />

            <ContentRoute exact path={AdminRoutes.SETTINGS_CONTRACT} component={ContractSettings} />

            <ContentRoute exact path={AdminRoutes.SETTINGS_CONTRACT_EDIT}>
              <ContractEdit contractBtnRef={contractBtnRef} history={history} id={id} />
            </ContentRoute>

          </Switch>
        </div>

      </CardBody>

    </Card>
  );
}