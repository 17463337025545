/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as UIHelpers from '../../../../modules/documents/_context/DocumentsUIHelpers';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';




export function OrderIdFormatter(cell, row, rowIndex) {
  return (
    <OverlayTrigger overlay={<Tooltip id="market-tooltip" className={row?.marketplace_url ? "d-flex font-weight-bold" : "d-none"}>{row?.marketplace_url}</Tooltip>}>
      <div className='d-flex align-items-center'>
        <span className='svg-icon svg-icon-lg'>
          {<Icon.Person className="text-dark-75" />}
        </span>
        <div className='d-flex flex-column font-weight-bold ml-3'>
          <span >
            {row?.customer_details?.name}
          </span>
          {/* <span >
            {moment(row?.created_at).format('MMMM YYYY')}
          </span> */}
        </div>

      </div>
    </OverlayTrigger>
  )
}


export function PaymentStatusFormatter(cell, row, rowIndex) {
  return (
    <span className={`label label-inline label-lg label-${cell === 'paid' ? 'outline-success' : 'danger'}`}>
      {UIHelpers.getPaymentStatus(cell)?.label ?? <FormattedMessage id="GENERAL.OTHER" />}
    </span>
  )
}


export function InvoiceFormatter(cell, row, rowIndex) {
  return (
    <OverlayTrigger overlay={<Tooltip id="create_invoice">{<FormattedMessage id={row?.error?.invoice ?? 'DOCUMENT.FULFILLMENT.SUCCESS'} />} </Tooltip>}>
      <span className="svg-icon svg-icon-sm">
        {cell !== undefined && (
          cell
            ? <Icon.CheckCircleFill className="text-success" />
            : <Icon.CheckCircleFill className="text-warning" />
        )}
      </span>
    </OverlayTrigger>
  )
}


export function WaybillFormatter(cell, row, rowIndex) {
  return (
    <OverlayTrigger overlay={<Tooltip id="create_invoice">{<FormattedMessage id={row?.error?.waybill ?? 'DOCUMENT.FULFILLMENT.SUCCESS'} />} </Tooltip>}>
      <span className="svg-icon svg-icon-sm">
        {cell !== undefined && (
          cell
            ? <Icon.CheckCircleFill className="text-success" />
            : <Icon.CheckCircleFill className="text-warning" />
        )}
      </span>
    </OverlayTrigger>
  )
}


export function EmailFormatter(cell, row, rowIndex) {
  return (
    <OverlayTrigger overlay={<Tooltip id="create_invoice">{row?.error?.email ?? <FormattedMessage id='DOCUMENT.FULFILLMENT.SUCCESS' />}</Tooltip>}>
      <span className="svg-icon svg-icon-sm">
        {cell !== undefined && (
          cell
            ? <Icon.CheckCircleFill className="text-success" />
            : <Icon.BanFill className="text-warning" />
        )}
      </span>
    </OverlayTrigger>
  )
}


export function ActionFormatter(cellContent, row, rowIndex, { deleteCompleteList }) {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id={`fulfillment-delete-tooltip-${rowIndex}`}><FormattedMessage id="DOCUMENT.FULFILLMENT.DELETE" /></Tooltip>}>
      <span className="d-inline-block">
        <a className="btn btn-icon btn-sm" onClick={() => deleteCompleteList(row?.id)} id={`btn_delete_${row?.id}`}>
          <Icon.Close className="text-danger" />
        </a>
      </span>
    </OverlayTrigger>
  );
}
