import React, { useState } from 'react';
import { DatePickerDropdown } from '_metronic/_partials/components';
import { SearchControl } from '_metronic/_partials/controls';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'app/hooks';



export function UserInvoicesFilter({ UIProps, UIContext }) {

  const query = useQuery();
  const [search, setSearchBase] = useState(query.get('search') ?? '');

  const handleSearch = (value) => {
    UIProps.setSearchQuery(value);
    setSearchBase(value);
  };


  return (
    <Row className='mb-3'>

      <Col sm="12" md="6" lg="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8" className='d-flex align-items-start justify-content-start px-md-0'>
        <DatePickerDropdown UIContext={UIContext} setFilterQuery={UIProps.setFilterQuery} type={true}/>
      </Col>
    </Row>
  );
}
