import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { RELATIVE_PATH, ModuleRoutes, BaseRoutes, AdminRoutes } from 'constants/moduleRoutes';
import { AdminDashboard, AdminTempUserPage, AdminUserContactPage, AdminUserPage, DocumentPages, ReportsUserPage, UserInvoices, SettingsPage} from '../admin';
import { ContentRoute, Layout } from '_metronic/layout';
import { SupportPage } from '../modules';
import { IconPage } from '../pages';



export default function AdminPage() {
  return (
    <Layout>

      <Switch>

        <Redirect exact from={RELATIVE_PATH} to={AdminRoutes.ADMIN_DASHBOARD} />

        <ContentRoute path={AdminRoutes.ADMIN_DASHBOARD} component={AdminDashboard} />
        <ContentRoute path={AdminRoutes.ADMIN_USERS} component={AdminUserPage} />
        <ContentRoute path={AdminRoutes.ADMIN_TEMP_USERS} component={AdminTempUserPage} />
        <ContentRoute path={AdminRoutes.ADMIN_USERS_REPORTS} component={ReportsUserPage} />
        <ContentRoute path={AdminRoutes.ADMIN_USERS_CONTACT} component={AdminUserContactPage} />
        {/* <ContentRoute path={AdminRoutes.ADMIN_USER_INVOICE} component={UserInvoices} /> */}
        <ContentRoute path={AdminRoutes.ADMIN_USER_DOCUMENT_BASE} component={DocumentPages} />
        <ContentRoute path={AdminRoutes.ADMIN_USER_DOCUMENT} component={UserInvoices} />

        <ContentRoute path={ModuleRoutes.SUPPORT} component={SupportPage} />
        <ContentRoute path={ModuleRoutes.ICON} component={IconPage} />
        <ContentRoute path={AdminRoutes.SETTINGS} component={SettingsPage} />

        <Redirect to={BaseRoutes.ERROR} />

      </Switch>

    </Layout>
  );
}
