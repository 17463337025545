import React from 'react';
import { Formik, Form } from 'formik';
// import { nameRegExp, phoneRegExp, maxValidation, minValidation, emailRegExp, vatRegExp } from '_metronic/_helpers';
// import { footerLength, headerLength } from 'app/modules/settings/document-settings/Text';
import { DirtyDialog } from '_metronic/_partials/dialogs';
// import { useIntl } from 'react-intl';
// import * as Yup from 'yup';



export function FormikHandler({ userInvoiceForEdit, btnRef, saveDocument, children, autoComplete, resetBtnRef, onKeyDown, id, success, onClose }) {

  // const { formatMessage: intl } = useIntl();

  // const documentSchema = Yup.object().shape({
  //   customer_details: Yup.object().shape({
  //     company: Yup.string()
  //       .max(100, maxValidation("100"))
  //       .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
  //       .matches(nameRegExp, intl({ id: "ADDRESS.COMPANY_MATCHES" })),
  //     name: Yup.string()
  //       .max(50, maxValidation("50"))
  //       .required(intl({ id: "GENERAL.NAME_REQUIRED" }))
  //       .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
  //       .matches(nameRegExp, intl({ id: "ADDRESS.NAME_MATCHES" })),
  //     email: Yup.string()
  //       .max(50, maxValidation("50"))
  //       .matches(emailRegExp, intl({ id: 'GENERAL.EMAIL_EMAIL' }))
  //       .email(intl({ id: "GENERAL.EMAIL_EMAIL" })),
  //     phone: Yup.string()
  //       .max(25, maxValidation("25"))
  //       .matches(phoneRegExp, intl({ id: 'GENERAL.TEL_MATCHES' }))
  //       .min(7, minValidation("7")),
  //     vat_id: Yup.string()
  //       .max(15, maxValidation("15"))
  //       .when([ 'invoice_address'], {
  //         is: ( invoice_address) => {
  //           return invoice_address.country_code === "DE"
  //         },
  //         then: Yup.string().matches(vatRegExp, intl({ id: 'GENERAL.VAT_ID_MATCHES' })),
  //         otherwise: schema => schema,
  //       })
  //   }),
  //   subtitle: Yup.object().shape({
  //     header: Yup.string()
  //       .max(headerLength, maxValidation(headerLength)),
  //     footer: Yup.string()
  //       .max(footerLength, maxValidation(footerLength)),
  //   }),
  //   notes: Yup.string()
  //     .max(1000, maxValidation("1000")),
  //   /*  delivery_date: Yup.date()
  //      .nullable()
  //      .min(id ? new Date(document?.order_date).toISOString().split("T")[0] : new Date().toISOString().split("T")[0], intl({ id: "DOCUMENT.SHIPPING.DELIVERY_DATE_MIN" })), */
  // });

  return (
    <Formik enableReinitialize={true} initialValues={userInvoiceForEdit}  onSubmit={saveDocument}>
      {({ handleSubmit, handleReset }) => (
        <Form onKeyDown={onKeyDown} autoComplete={autoComplete}>
          <DirtyDialog success={success} onClose={onClose} />
          {children}
          <button type="submit" className='d-none' ref={btnRef} onSubmit={() => handleSubmit()} id="btn_submit" />
          <button type="button" className='d-none' ref={resetBtnRef} onClick={() => handleReset()} id="btn_reset" />
        </Form>
      )}
    </Formik>
  );
}
