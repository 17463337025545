import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { LayoutSplashScreen } from '_metronic/layout';
import { AdminRoutes } from 'constants/moduleRoutes';
import { Icon } from '_metronic/_icons';
import UserInvoices from './UserInvoices';
import { useDocumentsUIContext } from 'app/modules/documents/_context/DocumentsUIContext';
import { DocumentActions } from 'app/modules/documents/_redux/actions';



export const documentContents = [

  {
    type: "orders",
    title: "DOCUMENT.ORDER_TITLE",
    plural: "DOCUMENT.ORDER_PLURAL",
    icon: <Icon.Order />,
    route: AdminRoutes.ADMIN_USER_ORDER,
    inputHeaderText: "Vielen Dank für Ihre Bestellung bei uns.",
    inputFooterText: "Bei Fragen oder Problemen zu Ihrer Bestellung helfen wir Ihnen gerne weiter.\nWie wünschen Ihnen einen schönen Tag."
  },
  {
    type: "invoices",
    title: "DOCUMENT.INVOICE_TITLE",
    plural: "DOCUMENT.INVOICE_PLURAL",
    icon: <Icon.Document />,
    route: AdminRoutes.ADMIN_USER_INVOICE,
    inputHeaderText: "Vielen Dank, dass Sie sich bei uns entschieden haben.",
    inputFooterText: "Bei Fragen oder Problemen zu Ihrer Rechnung helfen wir Ihnen gerne weiter.\nWie wünschen Ihnen einen schönen Tag."
  },
]


export default function GenerateDocument() {

  const history = useHistory();
  const dispatch = useDispatch();
  const { doc } = useParams();

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    requestedDocument: UIContext.requestedDocument,
    setupRequestedDocument: UIContext.setupRequestedDocument,
  }),
    [UIContext.requestedDocument, UIContext.setupRequestedDocument]
  );

  const getRequestedDocument = useSelector((state) => state.documents.requestedDocument);

  useEffect(() => {

    const getDocument = documentContents.find(item => item.type === doc);

    if (!getDocument) {
      history.push(AdminRoutes.ADMIN_USER_DOCUMENT);
    }

    UIProps.setupRequestedDocument(getDocument);
    dispatch(DocumentActions.setupRequestedDocument(getDocument));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, doc]);


  return !UIProps.requestedDocument || !(UIProps.requestedDocument.type === getRequestedDocument.type)
    ? <LayoutSplashScreen />
    : <UserInvoices key={UIProps.requestedDocument.type} name={UIProps.requestedDocument.type} />

}
