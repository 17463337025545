import React from 'react';
import { UserInvoicesCard } from './UserInvoicesCard';
import { SendEmailsDialog } from './user-invoices-dialogs/SendEmailsDialog';
import PrintDocument from './user-invoices-dialogs/PrintDocument';
import { InvoiceDetailsDialog } from './user-invoices-dialogs/InvoiceDetailsDialog';
import { ConvertingDocumentsDialog } from './user-invoice-convert/ConvertingDocumentsDialog';
import { EnterPaymentDialog } from './user-invoices-edit/payment/enter-payment-dialog/EnterPaymentDialog';



export function UserInvoicesPage() {

  return (
    <>
      <SendEmailsDialog />
      <PrintDocument />
      <InvoiceDetailsDialog />
      <ConvertingDocumentsDialog />
      <EnterPaymentDialog />

      <UserInvoicesCard />
    </>
  );
}
