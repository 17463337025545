import React, { useState, useCallback } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Card, CardBody, CardHeaderToolbar, CardSubHeader } from "_metronic/_partials/controls";
import { getCountryList } from "_metronic/_helpers";
import { AddressEditDialog } from "./AddressEditDialog";
import { FormattedMessage } from "react-intl";
import { useFormikContext } from "formik";
import { useLang } from "_metronic/i18n";
import { Icon } from '_metronic/_icons';
import { CardItems } from "_metronic/_partials/components";



export function AddressCard({ loading }) {

  const locale = useLang();

  const initialShow = { shippingShow: false, invoiceShow: false };

  const [show, setShow] = useState(initialShow);
  const invoiceAddressEdit = () => setShow({ ...show, invoiceShow: true });

  const closeAddressEditDialog = useCallback(() => {
    setShow(initialShow)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { values: { customer_details: { invoice_address } } } = useFormikContext();

  const ADDRESS_INFO = [
    {
      label: "GENERAL.NAME",
      content: invoice_address?.name,
    },
    (invoice_address?.company &&
    {
      label: "GENERAL.COMPANY",
      content: invoice_address?.company,
    }),
    {
      label: "GENERAL.ADDRESS",
      content:
        invoice_address && <div>
          <div>{invoice_address?.address_line1} {invoice_address?.address_line2}</div>
          <div>{invoice_address?.post_code} {invoice_address?.city}</div>
          <div>{getCountryList(locale).find(code => code.value === invoice_address?.country_code)?.label}</div>
        </div>
    },
  ].filter(Boolean);

  return (
    <>
      {(show.invoiceShow) &&
        <AddressEditDialog
          show={show.invoiceShow}
          onHide={closeAddressEditDialog}
          title={"ADDRESS.INVOICE"}
          loading={loading}
        />
      }

      <Card className="card-stretch gutter-b">

        <CardSubHeader icon={<Icon.GeoAlt />} title={<FormattedMessage id="ADDRESS.INVOICE" />}>
          <CardHeaderToolbar>
            <OverlayTrigger overlay={<Tooltip id="document-print-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
              <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={invoiceAddressEdit} disabled={loading} id="btn_edit_address">
                <Icon.PencilSquare />
              </Button>
            </OverlayTrigger>
          </CardHeaderToolbar>
        </CardSubHeader>


        <CardBody>
          <CardItems Content={ADDRESS_INFO} />
        </CardBody>
      </Card>

    </>
  );
}