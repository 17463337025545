import React from 'react'
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from "react-bootstrap";



export default function ContractSaveDialog({ showModal, handleSaveModal }) {
   return (
      <Modal show={showModal}>

         <Modal.Header closeButton>
            <Modal.Title>
               Edit Contract
            </Modal.Title>
         </Modal.Header>

         <Modal.Body className='text-center font-weight-bold'>
            <FormattedMessage id="SETTINGS.CONTRACTS.CONFIRMATION" />
         </Modal.Body>

         <Modal.Footer>
            <Button variant="light" onClick={() => handleSaveModal("cancel")}>
               <FormattedMessage id="GENERAL.CANCEL" />
            </Button>
            <Button variant="light-primary" onClick={() => handleSaveModal("save")}>
               <FormattedMessage id="GENERAL.SAVE" />
            </Button>
            <Button variant="primary" onClick={() => handleSaveModal("new")}>
               Save as New
            </Button>
         </Modal.Footer>

      </Modal>
   )
}
