import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import UserInfoDropdown from "../user-info/UserInfoDropdown";



export default function CardItems({ Content, type }) {
  return (
    Content?.length ? Content?.map((item, i) => (
      type === "column"
        ? <Row key={i} className={Content.length - 1 === i ? "mb-3" : "mb-5"}>
          <Col sm="12" className="d-flex align-items-center text-dark-50 font-size-lg font-weight-bold">
            <FormattedMessage id={item.label} />
            {item.info && <UserInfoDropdown description={<FormattedMessage id={item.info} />} />}
          </Col>
          <Col sm="12" className="font-size-lg font-weight-bold mt-2">
            {item.content}
          </Col>
        </Row>
        : <Row key={i} className={Content.length - 1 === i ? "mb-3" : "mb-5"}>
          <Col sm="5" md="4" className="d-flex align-items-center text-dark-50 font-size-lg font-weight-bold">
            <FormattedMessage id={item.label} />
            {item.info && <UserInfoDropdown description={<FormattedMessage id={item.info} />} />}
          </Col>
          <Col sm="7" md="8" className="font-size-lg font-weight-bold">
            {item.content}
          </Col>
        </Row>
    ))
      : <></>
  );
}
