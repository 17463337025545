import React, { createContext, useContext, useState } from 'react';


const UserSettingsUIContext = createContext({});
export const useUserSettingsUIContext = () => useContext(UserSettingsUIContext);



export function UserSettingsUIProvider({ children }) {

  const [ids, setIds] = useState([]);

  const [showEmailEditDialog, setShowEmailEditDialog] = useState(false);
  const openEmailEditDialog = () => setShowEmailEditDialog(true);
  const closeEmailEditDialog = () => setShowEmailEditDialog(false);

  const [showPasswordEditDialog, setShowPasswordEditDialog] = useState(false);
  const openPasswordEditDialog = () => setShowPasswordEditDialog(true);
  const closePasswordEditDialog = () => setShowPasswordEditDialog(false);

  const [showDeleteAccountDialog, setShowDeleteAccountDialog] = useState(false);
  const openAccountDeleteDialog = () => setShowDeleteAccountDialog(true);
  const closeAccountDeleteDialog = () => setShowDeleteAccountDialog(false);

  const [showClearCacheDialog, setShowClearCacheDialog] = useState(false);
  const openClearCacheDialog = () => setShowClearCacheDialog(true);
  const closeClearCacheDialog = () => setShowClearCacheDialog(false);

  const value = {
    ids,
    setIds,
    showEmailEditDialog,
    openEmailEditDialog,
    closeEmailEditDialog,

    showPasswordEditDialog,
    openPasswordEditDialog,
    closePasswordEditDialog,

    showDeleteAccountDialog,
    openAccountDeleteDialog,
    closeAccountDeleteDialog,

    showClearCacheDialog,
    openClearCacheDialog,
    closeClearCacheDialog,

  };

  return (
    <UserSettingsUIContext.Provider value={value}>
      {children}
    </UserSettingsUIContext.Provider>
  );
}