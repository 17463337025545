import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardSubHeader, SwitchControl, } from '_metronic/_partials/controls';
import { ConnectionsActions } from '../../connections/_redux/actions';
import { BlankMessage } from '_metronic/_partials/components';
import { UserInfoDropdown } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { Field } from 'formik';



const INPUT = [
  {
    name: "stock_control.kaufland",
    label: "Kaufland"
  },
  {
    name: "stock_control.amazon",
    label: "Amazon"
  },
  {
    name: "stock_control.ebay",
    label: "Ebay"
  },
  {
    name: "stock_control.shopware5",
    label: "Shopware5"
  },
  {
    name: "stock_control.shopware6",
    label: "Shopware6"
  },
  {
    name: "stock_control.shopify",
    label: "Shopify"
  },
  {
    name: "stock_control.woocommerce",
    label: "Woocommerce"
  },
  {
    name: "stock_control.prestashop",
    label: "Prestashop"
  },
  {
    name: "stock_control.etsy",
    label: "Etsy"
  }
]


export function StockControl() {

  const dispatch = useDispatch();

  const { connections } = useSelector(state => ({ connections: state.connections.entities }));

  useEffect(() => {
    dispatch(ConnectionsActions.getConnections());
  }, [dispatch]);


  return (
    <Card className="card-stretch gutter-b">

      <CardSubHeader
        title={<FormattedMessage id="SETTINGS.PRODUCT.STOCK_TITLE" />}
        icon={<Icon.Toggles />}
        info={<UserInfoDropdown description={<FormattedMessage id='USER_GUIDE.SETTINGS.PRODUCT_STOCK' />} />}
      />

      <CardBody>
        <Row>

          {connections?.length === 0
            ? <Col>
              <BlankMessage
                icon={<Icon.Connection />}
                title={<FormattedMessage id='CONNECTIONS.BLANK_MESSAGE_TITLE' />}
                message={<FormattedMessage id='CONNECTIONS.BLANK_MESSAGE' />}
                className='my-5'
              />
            </Col>
            : <>

              <Col md="12" className="mb-5">
                <FormattedMessage id="SETTINGS.PRODUCT_WARNING" />
              </Col>

              {INPUT.map(input => (connections?.length > 0 &&
                connections.filter((item) => input.name.includes(item.name)).slice(0, 1).map((item, i) => (
                  <Col sm="6" md="4" key={i} className="mb-3">
                    <Field component={SwitchControl}
                      {...{
                        name: input.name,
                        label: input.label,
                      }}
                    />
                  </Col>
                ))
              ))}

            </>
          }

        </Row>
      </CardBody>

    </Card>
  );
}
