import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PositionsTable } from './positions-table/PositionsTable';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { UserInfoDropdown } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { AdminActions } from 'app/admin/_redux/actions';



export function PositionsCard({ loading }) {

  const dispatch = useDispatch();

  //UIContext
  // const UIContext = useUserInvoicesUIContext();
  // const UIProps = useMemo(() => ({
  //   setIds: ProductUIContext.setIds,
  //   selectedProduct: UIContext.selectedProduct,
  // }), [
  //   UIContext, ProductUIContext,
  // ]);

  const isMarketplace = useSelector(state => !!state.admin.userInvoiceForEdit?.marketplace);
  const productSettings = useSelector(state => state.settings.productSettings.default_product);

  useEffect(() => {
    // return () => { UIProps.setIds([]); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (<>
    <Card className="gutter-b">

      <CardSubHeader
        icon={<Icon.Layers />}
        title={<FormattedMessage id="GENERAL.POSITIONS" />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='DOCUMENT.POSITION.INFO_TITLE' />}
          description={<FormattedMessage id={isMarketplace ? 'DOCUMENT.POSITION_ADD.SHOP_INFO_DESCRIPTION' : 'DOCUMENT.POSITION_ADD.LOCAL_INFO_DESCRIPTION'} />} />}
      >

        {!isMarketplace &&
          <Dropdown as={ButtonGroup} drop='down' size='sm'>

            <Button variant='outline-primary' className="svg-icon svg-icon-sm" disabled={true} id='btn_add_title'>
              <Icon.Plus />
              <span className="d-none d-sm-inline font-weight-bold ml-2">
                <FormattedMessage id="DOCUMENT.POSITION.PRODUCTS_TITLE" />
              </span>
            </Button>

            <Dropdown.Toggle split variant="outline-primary" id="dropdown-split-basic" />

            <Dropdown.Menu>
              <Dropdown.Item type='button' onClick={() => dispatch(AdminActions.addNewPositionRow(productSettings))} id='dropdown_new_position'>
                <FormattedMessage id="DOCUMENT.POSITION_NEW_POSITION" />
              </Dropdown.Item>
            </Dropdown.Menu>

          </Dropdown>}

      </CardSubHeader>

      <CardBody className="pt-0 px-0">
        <PositionsTable loading={loading} />
      </CardBody>

    </Card>

    {/* {UIProps.showProductEditDialog && (
      <ProductEdit modal show={UIProps.showProductEditDialog} onHide={UIProps.handleCloseProductEditDialog} />
    )} */}

    {/* <ProductSelectDialog /> */}

  </>);
}
