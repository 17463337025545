import React from 'react';
import { FormattedMessage } from 'react-intl';
import ActionTypes from './actionTypes';
import { toast } from 'react-toastify';



export class AdminActions {

  // Get all users
  static getUsers = (dateRange, queryParams) => {
    return {
      type: ActionTypes.GET_USERS,
      payload: {
        queryParams,
        dateRange
      },
    };
  };

  static getUsersSuccess = (users, count) => {
    return {
      type: ActionTypes.GET_USERS_SUCCESS,
      payload: {
        users,
        count
      },
    };
  };

  static getUsersFail = (error) => {
    return {
      type: ActionTypes.GET_USERS_FAIL,
      payload: {
        error
      },
    };
  };


  // Get  user
  static getUser = (id) => {
    return {
      type: ActionTypes.GET_USER,
      payload: {
        id
      },
    };
  };

  static getUserSuccess = (user) => {
    return {
      type: ActionTypes.GET_USER_SUCCESS,
      payload: {
        user,
      },
    };
  };

  static getUserFail = (error) => {
    return {
      type: ActionTypes.GET_USER_FAIL,
      payload: {
        error
      },
    };
  };



  // Update Users
  static updateUser = (id, user) => {
    return {
      type: ActionTypes.UPDATE_USER,
      payload: {
        id,
        user
      },
    };
  };

  static updateUserSuccess = (userId, updatedUser) => {
    toast.success(<FormattedMessage id="USER.REDUX.UPDATE_USER.SUCCESS" />);
    return {
      type: ActionTypes.UPDATE_USER_SUCCESS,
      payload: {
        userId,
        updatedUser
      },
    };
  };

  static updateUserFail = (error) => {
    toast.error(error?.data?.error?.message);
    return {
      type: ActionTypes.UPDATE_USER_FAIL,
      payload: {
        error
      },
    };
  };



  // Delete user
  static deleteUser = (id, user, queryParams) => {
    return {
      type: ActionTypes.DELETE_USER,
      payload: {
        id,
        user,
        queryParams
      },
    };
  };

  static deleteUserSuccess = (user) => {
    toast.success(<FormattedMessage id='ADMIN.USER.DELETE_MESSAGE.SUCCESS' values={{ name: user.name }} />);
    return {
      type: ActionTypes.DELETE_USER_SUCCESS,
      payload: {
        user
      },
    };
  };

  static deleteUserFail = (error) => {
    toast.error(error.message);
    return {
      type: ActionTypes.DELETE_USER_FAIL,
      payload: {
        error
      },
    };
  };

  

  // Get all temp users
  static getTempUsers = (queryParams) => {
    return {
      type: ActionTypes.GET_TEMP_USERS,
      payload: {
        queryParams
      },
    };
  };

  static getTempUsersSuccess = (tempUsers, count) => {
    return {
      type: ActionTypes.GET_TEMP_USERS_SUCCESS,
      payload: {
        tempUsers,
        count
      },
    };
  };

  static getTempUsersFail = (error) => {
    return {
      type: ActionTypes.GET_TEMP_USERS_FAIL,
      payload: {
        error
      },
    };
  };



  // Update Temp Users
  static updateTempUser = (tempUser) => {
    return {
      type: ActionTypes.UPDATE_TEMP_USER,
      payload: {
        tempUser
      },
    };
  };

  static updateTempUserSuccess = () => {
    toast.success(<FormattedMessage id="USER.REDUX.UPDATE_USER.SUCCESS" />);
    return {
      type: ActionTypes.UPDATE_TEMP_USER_SUCCESS,
    };
  };

  static updateTempUserFail = (error) => {
    toast.error(error.data.error.message);
    return {
      type: ActionTypes.UPDATE_TEMP_USER_FAIL,
      payload: {
        error
      },
    };
  };



  // Delete TempUser
  static deleteTempUser = (ids, queryParams) => {
    return {
      type: ActionTypes.DELETE_TEMP_USERS,
      payload: {
        ids,
        queryParams
      },
    };
  };

  static deleteTempUserSuccess = (ids) => {
    toast.success(<FormattedMessage id='ADMIN.REDUX.DELETE_TEMP_USER.SUCCESS' />);
    return {
      type: ActionTypes.DELETE_TEMP_USERS_SUCCESS,
      payload: {
        ids,
      },
    };
  };

  static deleteTempUserFail = error => {
    return {
      type: ActionTypes.DELETE_TEMP_USERS_FAIL,
      payload: {
        error: error,
      },
    };
  };


  // Get all users
  static getReportsUsers = (dateRange, queryParams) => {
    return {
      type: ActionTypes.GET_REPORTS_USERS,
      payload: {
        dateRange,
        queryParams
      },
    };
  };

  static getReportsUsersSuccess = (users, count) => {
    return {
      type: ActionTypes.GET_REPORTS_USERS_SUCCESS,
      payload: {
        users,
        count
      },
    };
  };

  static getReportsUsersFail = (error) => {
    return {
      type: ActionTypes.GET_REPORTS_USERS_FAIL,
      payload: {
        error
      },
    };
  };

  // Get all users contact
  static getUsersContact = (queryParams) => {
    return {
      type: ActionTypes.GET_USERS_CONTACT,
      payload: {
        queryParams
      },
    };
  };

  static getUsersContactSuccess = (users, count) => {
    return {
      type: ActionTypes.GET_USERS_CONTACT_SUCCESS,
      payload: {
        users,
        count
      },
    };
  };

  static getUsersContactFail = (error) => {
    return {
      type: ActionTypes.GET_USERS_CONTACT_FAIL,
      payload: {
        error
      },
    };
  };


  // Get user details
  static getUserCompany = (id, filter) => {
    return {
      type: ActionTypes.GET_USER_COMPANY,
      payload: {
        id,
        filter
      },
    };
  };

  static getUserCompanySuccess = (data) => {
    return {
      type: ActionTypes.GET_USER_COMPANY_SUCCESS,
      payload: {
        company: data.company,
        usage_report: data.usage_report,
      },
    };
  }

  static getUserCompanyFail = (error) => {
    return {
      type: ActionTypes.GET_USER_COMPANY_FAIL,
      payload: {
        error
      },
    };
  }


  static getOrderReport = (id, filter) => {
    return {
      type: ActionTypes.GET_ORDER_REPORT,
      payload: {
        id,
        filter
      },
    };
  };

  static getOrderReportSuccess = (data) => {
    return {
      type: ActionTypes.GET_ORDER_REPORT_SUCCESS,
      payload: {
        order_report: data.order_report,
      },
    };
  }

  static getOrderReportFail = (error) => {
    return {
      type: ActionTypes.GET_ORDER_REPORT_FAIL,
      payload: {
        error
      },
    };
  }


  static getUserLogEntries = (id, order, search, filter, page, pageSize) => {
    return {
      type: ActionTypes.GET_USER_LOG_ENTRIES,
      payload: {
        id,
        order,
        search,
        filter,
        page,
        pageSize
      },
    };
  };

  static getUserLogEntriesSuccess = (data) => {
    return {
      type: ActionTypes.GET_USER_LOG_ENTRIES_SUCCESS,
      payload: {
        log_entries: data.logEntries,
        total_log_entries: data.totalLogEntries,
      },
    };
  };

  static getUserLogEntriesFail = (error) => {
    return {
      type: ActionTypes.GET_USER_LOG_ENTRIES_FAIL,
      payload: {
        error
      },
    };
  };


  /**
 * get all user invoices
 */
  static getUserInvoices = (queryParams, dateRange) => {
    return {
      type: ActionTypes.GET_USER_INVOICES,
      payload: {
        queryParams,
        dateRange
      },
    };
  };

  static getUserInvoicesSuccess = (data, count) => {
    return {
      type: ActionTypes.GET_USER_INVOICES_SUCCESS,
      payload: {
        data,
        count,
      },
    };
  };

  static getUserInvoicesFail = () => {
    return {
      type: ActionTypes.GET_USER_INVOICES_FAIL,
    };
  };

  static getUserInvoiceById = (id) => {
    return {
      type: ActionTypes.GET_USER_INVOICE_BY_ID,
      payload: {
        id
      },
    };
  }

  static getUserInvoiceByIdSuccess = (data) => {
    return {
      type: ActionTypes.GET_USER_INVOICE_BY_ID_SUCCESS,
      payload: {
        data
      },
    };
  }

  static getUserInvoiceByIdFail = () => {
    return {
      type: ActionTypes.GET_USER_INVOICE_BY_ID_FAIL,
    };
  }


  static getUserInvoicesByIds = (ids) => {
    return {
      type: ActionTypes.GET_USER_INVOICES_BY_IDS,
      payload: {
        ids
      },
    };
  }

  static getUserInvoicesByIdsSuccess = (data) => {
    return {
      type: ActionTypes.GET_USER_INVOICES_BY_IDS_SUCCESS,
      payload: {
        data
      },
    };
  }

  static getUserInvoicesByIdsFail = () => {
    return {
      type: ActionTypes.GET_USER_INVOICES_BY_IDS_FAIL,
    };
  }

  // -------------------------------------- UPDATE USER INVOICE
  static patchUserInvoice = (id, data) => {
    return {
      type: ActionTypes.PATCH_USER_INVOICE,
      payload: {
        id,
        data
      },
    };
  }

  static patchUserInvoiceSuccess = (id, data) => {
    toast.success(<FormattedMessage id="USER.REDUX.UPDATE_USER.SUCCESS" />);
    return {
      type: ActionTypes.PATCH_USER_INVOICE_SUCCESS,
      payload: {
        id,
        data
      },
    };
  }

  static patchUserInvoiceFail = (error) => {
    toast.error(error.data.error.message);
    return {
      type: ActionTypes.PATCH_USER_INVOICE_FAIL,
      payload: {
        error
      },
    };
  }
  //UPDATE USER INVOICE
  static updateUserInvoice = (document) => {
    return {
      type: ActionTypes.UPDATE_USER_INVOICE,
      payload: {
        document
      },
    };
  };

  static updateUserInvoiceSuccess = () => {
    toast.success(<FormattedMessage id='DOCUMENT.REDUX.UPDATE_DOCUMENT.SUCCESS' />);
    return {
      type: ActionTypes.UPDATE_USER_INVOICE_SUCCESS,
    };
  };

  static updateUserInvoiceFail = (error) => {
    toast.error(<FormattedMessage id='DOCUMENT.REDUX.UPDATE_DOCUMENT.FAIL' />);
    return {
      type: ActionTypes.UPDATE_USER_INVOICE_FAIL,
      payload: {
        error
      },
    };
  };


  // Add New Position Row
  static addNewPositionRow = (settings) => {
    return {
      type: ActionTypes.ADD_NEW_POSITION_ROW,
      payload: {
        settings
      },
    };
  };


  // Update Position
  static updatePosition = (updatedRow, column) => {
    return {
      type: ActionTypes.UPDATE_POSITION,
      payload: {
        updatedRow,
        column,
      },
    };
  };

  // Delete Position
  static deletePosition = (id) => {
    return {
      type: ActionTypes.DELETE_POSITION,
      payload: {
        id,
      },
    };
  };

  // PRINT DOCUMENT
  static printDocument = (data, queryParams) => {
    return {
      type: ActionTypes.PRINT_DOCUMENT,
      payload: {
        data,
        queryParams,
      },
    };
  };

  static printDocumentSuccess = (response) => {
    response?.error && toast.error(response.error);
    return {
      type: ActionTypes.PRINT_DOCUMENT_SUCCESS,
      payload: {
        response,
      },
    };
  };

  static printDocumentFail = (err) => {
    return {
      type: ActionTypes.PRINT_DOCUMENT_FAIL,
      payload: {
        err
      },
    };
  };


  // Create Fulfillment Methods
  static createFulfillments = (data) => {
    return {
      type: ActionTypes.CREATE_FULFILLMENT_METHODS,
      payload: {
        data
      },
    };
  };

  static createFulfillmentsSuccess = (data) => {

    const counts = data.reduce((acc, item) => {
      if (item.invoice) { acc.invoiceLength++; }
      if (item.fulfillment) { acc.fulfillmentLength++; }
      return acc;
    }, { invoice: 0, fulfillment: 0 });

    counts.fulfillment > 0 && toast.success(<FormattedMessage id={counts.fulfillment > 1 ? 'DOCUMENT.REDUX.CREATE_FULFILLMENT_METHODS.SUCCESS_PLURAL' : 'DOCUMENT.REDUX.CREATE_FULFILLMENT_METHODS.SUCCESS'} values={{ length: counts.fulfillment }} />);
    counts.invoice > 0 && toast.success(<FormattedMessage id={counts.invoice > 1 ? 'DOCUMENT.REDUX.CONVERT_DOCUMENTS.SUCCESS_PLURAL' : 'DOCUMENT.REDUX.CONVERT_DOCUMENTS.SUCCESS'} values={{ length: counts.invoice }} />);

    return {
      type: ActionTypes.CREATE_FULFILLMENT_METHODS_SUCCESS,
      payload: {
        data
      },
    };
  };

  static createFulfillmentsFail = (error) => {
    toast.error(<FormattedMessage id='DOCUMENT.REDUX.CREATE_FULFILLMENT_METHODS.FAIL' />);
    return {
      type: ActionTypes.CREATE_FULFILLMENT_METHODS_FAIL,
      payload: {
        error,
      },
    };
  };

  static deleteCompleteList = (id) => {
    return {
      type: ActionTypes.DELETE_COMPLETE_LIST,
      payload: {
        id
      },
    };
  };


  // Enter Payment
  static enterPayment = (paymentValues, paymentStatus, documentId) => {
    return {
      type: ActionTypes.ENTER_PAYMENT,
      payload: {
        paymentValues,
        paymentStatus,
        documentId
      },
    };
  };

  static enterPaymentSuccess = (paymentValues, paymentStatus, documentId, history) => {
    //FIXME:BE den mesaj geliyor mesaja yansitilabilir
    toast.success(<FormattedMessage id='DOCUMENT.REDUX.ENTER_PAYMENT.SUCCESS' />);
    return {
      type: ActionTypes.ENTER_PAYMENT_SUCCESS,
      payload: {
        paymentValues,
        paymentStatus,
        documentId,
        history
      },
    };
  };

  static enterPaymentFail = (error) => {
    return {
      type: ActionTypes.ENTER_PAYMENT_FAIL,
      payload: {
        error
      },
    };
  };


  // Delete Enter Payment
  static deleteEnteredPayment = (paymentId, documentId) => {
    return {
      type: ActionTypes.DELETE_ENTERED_PAYMENT,
      payload: {
        paymentId,
        documentId
      },
    };
  };

  static deleteEnteredPaymentSuccess = (paymentId, documentId) => {
    return {
      type: ActionTypes.DELETE_ENTERED_PAYMENT_SUCCESS,
      payload: {
        paymentId,
        documentId
      },
    };
  };


  static deleteEnteredPaymentFail = (error) => {
    return {
      type: ActionTypes.DELETE_ENTERED_PAYMENT_FAIL,
      payload: {
        error
      },
    };
  };

  // Users Action Creators
  static cleanUpUserModule = () => {
    return {
      type: ActionTypes.CLEAN_UP_USER_MODULE,
    };
  };

  static cleanUpUserModuleParams = (params) => {
    return {
      type: ActionTypes.CLEAN_UP_USER_MODULE_PARAMS,
      payload: {
        params
      },
    };
  };

}