import React, { useCallback, useState } from 'react';
import { Card, CardBody, CardHeaderToolbar, CardSubHeader } from '_metronic/_partials/controls';
import { FormattedMessage } from 'react-intl';
import { UserInfoDropdown } from '_metronic/_partials';
import { useFormikContext } from 'formik';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { TextEditDialog } from './TextEditDialog';
import { CardItems } from '_metronic/_partials/components';



export function TextCard({ documentId, loading }) {

  const [show, setShow] = useState(false);
  const closeTextEditDialog = useCallback(() => {
    setShow(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { values: { notes, subtitle: { header, footer } } } = useFormikContext();


  const ADDRESS_INFO = [
    (header &&
    {
      label: "GENERAL.HEADER",
      content: header,
    }),
    (footer &&
    {
      label: "GENERAL.FOOTER",
      content: footer,
    }),
    (notes &&
    {
      label: "DOCUMENT.HEADER_COURSE",
      content: notes,
    }),
  ].filter(Boolean);

  return (

    <>
      {show &&
        <TextEditDialog
          show={show}
          onHide={closeTextEditDialog}
          title={"ADDRESS.INVOICE"}
          loading={loading}
          documentId={documentId}
        />
      }

      <Card className="card-stretch">

        <CardSubHeader
          icon={<Icon.TextIndentLeft />}
          title={<FormattedMessage id="GENERAL.HEADER&FOOTER" />}
          info={<UserInfoDropdown
            title={<FormattedMessage id={'DOCUMENT.HEADER.INFO_TITLE'} />}
            description={<FormattedMessage id={'DOCUMENT.HEADER.INFO_DESCRIPTION'} />} />}
        >
          <CardHeaderToolbar>
            <OverlayTrigger overlay={<Tooltip id="document-print-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
              <Button variant='outline-primary' size='sm' className="svg-icon svg-icon-sm" onClick={() => setShow(true)} disabled={loading} id="btn_edit_address">
                <Icon.PencilSquare />
              </Button>
            </OverlayTrigger>
          </CardHeaderToolbar>
        </CardSubHeader>

        <CardBody>
          <CardItems Content={ADDRESS_INFO} type={'column'} />
        </CardBody>
      </Card>
    </>
  );
}
