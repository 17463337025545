import React from 'react'
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { CardItems } from '_metronic/_partials/components';
import { UIDates } from "_metronic/_helpers";
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export default function Info({ contract }) {

  const USER_INFO = [
    {
      label: "SETTINGS.CREATED_AT",
      content: UIDates.formatDateTime(contract?.created_at),
    },
    {
      label: "SETTINGS.UPDATED_AT",
      content: UIDates.formatDateTime(contract?.updated_at),
    },
    {
      label: "GENERAL.FILTER.TAGS",
      content: contract.tags?.map((item, i) => (
        <div key={i} className={`label label-inline label-outline-info mr-2`}>
          <span className="font-weight-bold text-nowrap">{item}</span>
        </div>
      )),
    },
  ];


  return (
    <Card className="flex-xl-row-auto w-xl-200px ml-xl-8" style={{ height: "fit-content" }}>
      <CardSubHeader icon={<Icon.InfoCircle />} title={<FormattedMessage id="ADMIN.USER.INFO" />} />
      <CardBody>
        <CardItems Content={USER_INFO} type={'column'} />
      </CardBody>
    </Card>
  );
}
