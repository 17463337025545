/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export const ActionsColumnFormatter = (cellContent, row, rowIndex, { openEditDocumentDialog, openPrintDocumentDialog }) => {
  return (
    <>
      <OverlayTrigger overlay={<Tooltip id="customers-edit-tooltip"><FormattedMessage id="GENERAL.EDIT" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-primary btn-sm" onClick={() => openEditDocumentDialog(row.id)} id={`btn_edit_${row.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.PencilSquare />
          </span>
        </a>
      </OverlayTrigger>

      <OverlayTrigger overlay={<Tooltip id="shipping-print-tooltip"><FormattedMessage id="GENERAL.PRINT" /></Tooltip>}>
        <a className="btn btn-icon btn-hover-info btn-sm" onClick={() => openPrintDocumentDialog(row.id)} id={`btn_print_${row?.id}`}>
          <span className="svg-icon svg-icon-md">
            <Icon.Printer />
          </span>
        </a>
      </OverlayTrigger>
    </>
  )
};
