import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { SVFormControl, SVReactSelect } from '_metronic/_partials/controls';
import { streetRegExp, nameRegExp, plzRegExp, getCountryList } from '_metronic/_helpers';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '_metronic/_icons';
import * as Yup from 'yup';
import { useLang } from '_metronic/i18n';
import { getTaxStatus } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { AdminActions } from 'app/admin/_redux/actions';




const maxValidation = (name) => <FormattedMessage id="GENERAL.MAX" values={{ name }} />

export function AddressEditDialog({ show, onHide, title, loading }) {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  const locale = useLang();

  const { values: { id, customer_details, tax }, setFieldValue } = useFormikContext();
  const company = useSelector(state => state.company.companyBaseData);

  const AddressEditSchema = Yup.object().shape({
    invoice_address: Yup.object().shape({
      name: Yup.string()
        .max(50, maxValidation("50"))
        .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
        .matches(nameRegExp, intl({ id: "ADDRESS.NAME_MATCHES" }))
        /* .when('company', {
          is: company => company === undefined,
          then: Yup.string().required(""),
        }) */
        .required(intl({ id: "GENERAL.NAME_REQUIRED" })),
      company: Yup.string()
        .max(100, maxValidation("100"))
        .matches(nameRegExp, intl({ id: "ADDRESS.COMPANY_MATCHES" })),
      address_line1: Yup.string()
        .max(150, maxValidation("150"))
        .required(intl({ id: "ADDRESS.STREET_REQUIRED" }))
        .when('country_code', {
          is: 'DE',  // Sadece country_code 'DE' olduğunda matches kontrolü yap
          then: schema => schema.matches(streetRegExp, intl({ id: "ADDRESS.STREET_MATCHES" })),
          otherwise: schema => schema,  // Diğer durumlarda matches kontrolü olmadan devam et
        })
        .required(intl({ id: "ADDRESS.STREET_REQUIRED" })),
      address_line2: Yup.string()
        .max(150, maxValidation("150")),
      post_code: Yup.string()
        .max(10, intl({ id: "ADDRESS.MAX_CHAR" }, { name: "10" }))
        .required(intl({ id: "ADDRESS.POSTCODE_REQUIRED" }))
        .when('country_code', {
          is: 'DE', // only apply the matches validation when country_code is 'DE'
          then: Yup.string().matches(plzRegExp, intl({ id: "ADDRESS.PLZ_MATCHES" }))
        })
        .typeError(intl({ id: "ADDRESS.PLZ_TYP" })),
      city: Yup.string()
        .max(50, intl({ id: "ADDRESS.MAX_CHAR" }, { name: "50" }))
        .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
        // .matches(ortRegExp, intl({ id: "ADDRESS.LOCATION_MATCHES" }))
        .required(intl({ id: "ADDRESS.LOCATION_REQUIRED" })),
      country_code: Yup.string()
        .required(intl({ id: "ADDRESS.COUNTRY_REQUIRED" })),
    }),

  });

  const onSubmit = (values) => {
    const { invoice_address } = values
    const taxStatus = getTaxStatus(company?.address?.country_code, invoice_address.country_code);
    tax.category = taxStatus;
    setFieldValue('tax.category', taxStatus);
    const data = {
      customer_details: {
        ...customer_details,
        invoice_address: invoice_address
      },
    }

    dispatch(AdminActions.patchUserInvoice(id, data));
    onHide();
  };

  const INPUTS = [
    {
      name: "invoice_address.name",
      label: "GENERAL.NAME",
      size: "12",
      required: true,
    },
    {
      name: "invoice_address.company",
      label: "GENERAL.COMPANY",
      size: "12",
    },
    {
      name: "invoice_address.address_line1",
      label: "ADDRESS.STREET",
      size: "12",
      required: true,
    },
    {
      name: "invoice_address.address_line2",
      label: "ADDRESS.SUFFIX",
      size: "12",
    },
    {
      name: "invoice_address.post_code",
      label: "ADDRESS.PLZ",
      size: "6",
      required: true,
    },
    {
      name: "invoice_address.city",
      label: "ADDRESS.CITY",
      size: "6",
      required: true,
    },
    {
      name: "invoice_address.country_code",
      label: "ADDRESS.COUNTRY",
      required: true,
      options: getCountryList(locale),
      component: SVReactSelect,
      size: "12",
      className: "mb-0"
    }
  ];

  return (
    <Modal size="md" show={show} onHide={onHide} keyboard={false} aria-labelledby="example-modal-sizes-title-lg" centered>

      <Modal.Header>
        <Modal.Title>
          <Icon.GeoAlt className="mr-3" />
          <FormattedMessage id={title} />
        </Modal.Title>
      </Modal.Header>

      <Formik enableReinitialize={true} initialValues={customer_details} validationSchema={AddressEditSchema} onSubmit={onSubmit}>
        <Form>

          <Modal.Body>
            <Row>

              {INPUTS.map((item, i) => (
                <Col lg={item.size} key={i}>
                  <Field component={item.component || SVFormControl}
                    {...{
                      name: item.name,
                      label: intl({ id: item.label }),
                      withFeedbackLabel: true,
                      options: item.options,
                      feedbackLabel: true,
                      required: item.required,
                      className: item.className,
                      disabled: loading,
                    }}
                  />
                </Col>
              ))}

            </Row>
          </Modal.Body>

          <Modal.Footer>

            <Button variant='light' onClick={onHide} id="btn_cancel_modal">
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>

            <Button variant='primary' type="submit" id='btn_update_modal'>
              <FormattedMessage id="GENERAL.UPDATE" />
            </Button>

          </Modal.Footer>

        </Form>
      </Formik>

    </Modal>
  );
}