import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { SVFormControl, SVReactSelectCreatable } from '_metronic/_partials/controls';
import { nameRegExp, emailRegExp, phoneRegExp, minValidation, vatRegExp } from '_metronic/_helpers';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '_metronic/_icons';
import * as Yup from 'yup';
import { TAX_CATEGORY } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { AdminActions } from 'app/admin/_redux/actions';




const maxValidation = (name) => <FormattedMessage id="GENERAL.MAX" values={{ name }} />

export function CustomerEditDialog({ show, onHide, title, loading }) {

  const [taxCategory, setTaxCategory] = useState({
    show: false,
    existing: { value: "", label: "" },
    newCategory: { value: "", label: "" },
    country: "",
    isVatId: false
  });

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();
  // const locale = useLang();

  const { userInvoiceForEdit } = useSelector(state => ({
    userInvoiceForEdit: state.admin.userInvoiceForEdit
  }));

  const { values: { id, customer_details, tax } } = useFormikContext();

  const taxCategoryOptions = TAX_CATEGORY.map(item => ({
    value: item.value,
    label: intl({ id: item.label })
  }));

  const handleTaxCategoryChange = (value) => {
    const unitOptions = taxCategoryOptions.find(item => item.value === value?.value);

    if (unitOptions.value !== tax.category) {
      setTaxCategory({ ...taxCategory, show: true, newCategory: unitOptions, isVatId: customer_details.vat_id === "" })
    }
  };

  const customerEditSchema = Yup.object().shape({
    customer_details: Yup.object().shape({
      company: Yup.string()
        .max(100, maxValidation("100"))
        .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
        .matches(nameRegExp, intl({ id: "ADDRESS.COMPANY_MATCHES" })),
      name: Yup.string()
        .max(50, maxValidation("50"))
        .required(intl({ id: "GENERAL.NAME_REQUIRED" }))
        .trim(intl({ id: 'AUTH.GENERAL.BLANK_CHARACTER' }))
        .matches(nameRegExp, intl({ id: "ADDRESS.NAME_MATCHES" })),
      email: Yup.string()
        .max(50, maxValidation("50"))
        .matches(emailRegExp, intl({ id: 'GENERAL.EMAIL_EMAIL' }))
        .email(intl({ id: "GENERAL.EMAIL_EMAIL" }))
        .when("source", { // amazon bazen mail verisini göndermiyor. Bu yüzden marketplace olanlarda mail zorunlu olmayacak.
          is: (source) => {
            const marketplace = source?.marketplace;
            return Array.isArray(marketplace) ? marketplace.length === 0 : !marketplace;
          },
          then: Yup.string().required(intl({ id: "GENERAL.EMAIL_REQUIRED" }))
        }),
      phone: Yup.string()
        .max(25, maxValidation("25"))
        .matches(phoneRegExp, intl({ id: 'GENERAL.TEL_MATCHES' }))
        .min(7, minValidation("7")),
      vat_id: Yup.string()
        .max(15, maxValidation("15"))
        .when(['invoice_address'], {
          is: (invoice_address) => {
            return invoice_address.country_code === "DE"
          },
          then: Yup.string().matches(vatRegExp, intl({ id: 'GENERAL.VAT_ID_MATCHES' })),
          otherwise: schema => schema,
        })
    }),

  });

  const onSubmit = (values) => {

    const { customer_details, tax } = values;
    const data = {
      customer_details: {
        ...customer_details,
      },
      tax: {
        ...tax,
        category: tax.category
      }
    }

    dispatch(AdminActions.patchUserInvoice(id, data));
    onHide();
  };

  const INPUTS = [
    {
      name: 'customer_details.name',
      label: "GENERAL.NAME",
      size: "12",
      required: true,
    },
    {
      name: 'customer_details.company',
      label: "GENERAL.COMPANY",
      size: "12",
    },
    {
      name: 'customer_details.email',
      label: "GENERAL.EMAIL",
      size: "12",
    },
    {
      name: 'customer_details.phone',
      label: "GENERAL.PHONE",
      size: "12",
    },
    {
      name: 'customer_details.vat_id',
      label: "GENERAL.US_NUMBER",
      // onBlur: (e) => handleVatIdChange(e.target.value),

    },
    {
      name: "tax.category",
      label: "DOCUMENT.PAYMENT_CATEGORY",
      component: SVReactSelectCreatable,
      onChange: handleTaxCategoryChange,
      value: taxCategoryOptions.find(item => item.value === tax.category),
      options: taxCategoryOptions,
    }
  ];

  return (
    <Modal size="md" show={show} onHide={onHide} keyboard={false} aria-labelledby="example-modal-sizes-title-lg" centered>

      <Modal.Header>
        <Modal.Title>
          <Icon.Person className="mr-3" />
          <FormattedMessage id='CUSTOMER.UPDATE_CUSTOMER' />
        </Modal.Title>
      </Modal.Header>

      <Formik enableReinitialize={true} initialValues={userInvoiceForEdit} validationSchema={customerEditSchema} onSubmit={onSubmit}>
        <Form>

          <Modal.Body>
            <Row>

              {INPUTS.map((item, i) => (
                <Col lg={item.size} key={i}>
                  <Field component={item.component || SVFormControl}
                    {...{
                      name: item.name,
                      value: item.value,
                      label: intl({ id: item.label }),
                      withFeedbackLabel: true,
                      options: item.options,
                      onChange: item.onChange,
                      feedbackLabel: true,
                      required: item.required,
                      className: item.className,
                      disabled: loading,
                    }}
                  />
                </Col>
              ))}

            </Row>
          </Modal.Body>

          <Modal.Footer>

            <Button variant='light' onClick={onHide} id="btn_cancel_modal">
              <FormattedMessage id="GENERAL.CANCEL" />
            </Button>

            <Button variant='primary' type="submit" id='btn_update_modal'>
              <FormattedMessage id="GENERAL.UPDATE" />
            </Button>

          </Modal.Footer>

        </Form>
      </Formik>

    </Modal>
  );
}